import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../Services/UserContext";
import { BASE_URL } from "../../Services";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import "react-toastify/dist/ReactToastify.css";

function FAQList() {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/faqs`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        setFaqs(response.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchFaqs();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/dashboard/faqs/${id}/edit`);
  };

  const handleDeleteClick = async (id, closeModal) => {
    try {
      await axios.delete(`${BASE_URL}/faqs/${id}`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      setFaqs(faqs.filter((faq) => faq.id !== id));
      toast.success("FAQ deleted successfully!");
      closeModal(false);
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const handleAddClick = () => {
    navigate("/dashboard/faqs/create");
  };
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
};


  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar  onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <Loader />
            </div>
          ) : (
            <DynamicTable
              title="FAQ List"
              headers={[
                { id: "question", label: "Question" },
                { id: "answer", label: "Answer" },
              ]}
              data={faqs}
              onEdit={handleEditClick}
              onDelete={handleDeleteClick}
              onAdd={handleAddClick}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FAQList;
