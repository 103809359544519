import React, { useState } from "react";

const DynamicTable = ({
  title,
  headers,
  data,
  onEdit,
  onDelete,
  onAdd,
  onView,
  hideActions = false,
  showViewButton = false,
  extraActions,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});

  const toggleReadMore = (id) => {
    setExpandedRows((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const filteredData = data.filter((item) =>
    headers.some((header) =>
      (item[header.id] || "")
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="dashboard-content p-4">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          {" "}
          <h1 className="fs-3 fw-bold text-dark p-2 text-start mb-2 rounded">
            {title}
          </h1>
        </div>
        <div className="d-flex gap-2 w-50 justify-content-end align-items-end">
          {onAdd && (
            <button
              className="btn btn-primary shadow-sm fw-bold"
              onClick={onAdd}
            >
              <i className="bi bi-plus-circle me-2"></i>Add New
            </button>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <input
          type="text"
          className="form-control shadow-sm"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="d-flex align-items-center justify-content-end">
          {extraActions && extraActions}
        </div>
      </div>

      <div className="table-responsive">
        <div style={{ overflowX: "auto", width: "100%", whiteSpace: "nowrap" }}>
          <table
            className="table table-hover table-striped table-bordered shadow-sm rounded"
            style={{ fontSize: "14px" }}
          >
            <thead className="table-dark">
              <tr>
                <th className="text-center" style={{ width: "5%" }}>
                  Sr.
                </th>
                {headers.map((header) => (
                  <th key={header.id} className="align-middle">
                    {header.label}
                  </th>
                ))}
                {!hideActions && (onEdit || onDelete || showViewButton) && (
                  <th>Actions</th>
                )}
              </tr>
            </thead>

            <tbody>
              {filteredData.length ? (
                filteredData.map((item, index) => (
                  <tr key={item.id}>
                    <td className="align-middle">{index + 1}</td>
                    {headers.map((header) => (
                      <td
                        key={header.id}
                        className="align-middle text-truncate"
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace:
                            header.id === "message" && expandedRows[index]
                              ? "wrap"
                              : "nowrap",
                          maxWidth: "400px",
                        }}
                      >
                        {header.type === "image" ? (
                          <img
                            src={item[header.id]}
                            alt="Item"
                            style={{
                              width: "100%",
                              height: "100px",
                              objectFit: "fit",
                              borderRadius: "5px",
                            }}
                          />
                        ) : header.id === "message" ? (
                          <>
                            {expandedRows[index]
                              ? item[header.id]
                              : `${item[header.id]?.slice(0, 25)}. `}
                            {item[header.id]?.length > 20 && (
                              <button
                                onClick={() => toggleReadMore(index)}
                                className="btn btn-link p-0"
                                style={{ color: "#0d6efd", fontSize: "14px" }}
                              >
                                {expandedRows[index]
                                  ? "Read Less"
                                  : "Read More"}
                              </button>
                            )}
                          </>
                        ) : header.accessor ? (
                          header.accessor(item)
                        ) : (
                          item[header.id] || "N/A"
                        )}
                      </td>
                    ))}

                    {!hideActions && (onEdit || onDelete || showViewButton) && (
                      <td className="text-center align-middle">
                        <div className="d-flex gap-2 justify-content-center">
                          {showViewButton && (
                            <button
                              className="btn btn-info btn-sm"
                              onClick={() => onView(item.id)}
                            >
                              <i className="bi bi-eye"></i>
                            </button>
                          )}
                          {onEdit && (
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={() => onEdit(item.id)}
                            >
                              <i className="bi bi-pencil"></i>
                            </button>
                          )}
                          {onDelete && (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                setItemToDelete(item.id);
                                setShowDeleteModal(true);
                              }}
                            >
                              <i className="bi bi-trash"></i>
                            </button>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={headers.length + 1} className="text-center">
                    No records found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showDeleteModal && (
        <div
          className="modal fade show d-block mt-3"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDeleteModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this item?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => onDelete(itemToDelete, setShowDeleteModal)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DynamicTable;
