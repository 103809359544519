import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import htmlTruncate from "html-truncate";

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });

        if (
          response.data.status === 200 &&
          Array.isArray(response.data.blogs)
        ) {
          setBlogs(response.data.blogs);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        toast.error(
          `Error fetching blogs: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [userData]);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={setIsSidebarOpen} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <Loader />
            </div>
          ) : (
            <DynamicTable
              title="Blogs List"
              headers={[
                { id: "slug", label: "Slug" },
                { id: "heading", label: "Heading" },
                {
                  id: "description",
                  label: "Description",
                  accessor: (row) => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: htmlTruncate(row.description, 20),
                      }}
                    />
                  ),
                },
                { id: "blog_image", label: "Image", type: "image" },
              ]}
              data={blogs}
              onEdit={(id) => {
                const blog = blogs.find((blog) => blog.id === id);
                if (blog) {
                  navigate(`/dashboard/blogs/${blog.slug}/edit`);
                }
              }}
              onDelete={async (id, closeModal) => {
                await axios.delete(`${BASE_URL}/blogs/${id}`, {
                  headers: { Authorization: `Bearer ${userData?.token}` },
                });
                setBlogs(blogs.filter((blog) => blog.id !== id));
                toast.success("Blog deleted successfully!");
                closeModal(false);
              }}
              onAdd={() => navigate("/dashboard/blogs/add")}
              
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default BlogList;
