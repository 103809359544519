import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../Services/UserContext";
import { ToastContainer } from "react-toastify";
import Sidebar from "./sidebar/Sidebar";
import Loader from "../UserSide/Components/LoaderComponent";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

function AdminDashboard() {
  const { users, getAllUsers, handleDeleteUser } = useUser();
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedUsers, setSortedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const entriesPerPage = 10;

  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then(() => setLoading(false))
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
    const interval = setInterval(() => {
      getAllUsers();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (users && users.length > 0) {
      setSortedUsers(users);
      setLoading(false);
    }
  }, [users]);

  useEffect(() => {
    const filteredUsers = users.filter((user) => {
      const userDate = new Date(user.created_at);
      const dateMatch =
        (!startDate || userDate >= new Date(`${startDate}T00:00:00Z`)) &&
        (!endDate || userDate <= new Date(`${endDate}T23:59:59Z`));

      return (
        ((user.name &&
          user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.email &&
            user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.role &&
            user.role.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone &&
            user.phone.toLowerCase().includes(searchTerm.toLowerCase()))) &&
        dateMatch
      );
    });
    const sortedFilteredUsers = filteredUsers.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    setSortedUsers(sortedFilteredUsers);
    setCurrentPage(1);
  }, [searchTerm, users, startDate, endDate]);
  const paginatedUsers = sortedUsers.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const userRows = users
      .filter((user) => user.role === "user")
      .map((user, index) => [
        index + 1,
        user.name,
        user.email,
        user.phone,
        user.role,
      ]);

    doc.autoTable({
      head: [["SR No.", "Name", "Email", "Phone", "Role"]],
      body: userRows,
    });
    doc.save("Enaam_users_list.pdf");
  };

  const downloadExcel = () => {
    const userRows = users
      .filter((user) => user.role === "user")
      .map((user, index) => ({
        "SR No.": index + 1,
        Name: user.name,
        Email: user.email,
        Phone: user.phone,
        Role: user.role,
        Date: new Date(user.created_at).toLocaleDateString(),
      }));
    const worksheet = XLSX.utils.json_to_sheet(userRows);
    const columnWidths = [
      { wch: 8 },
      { wch: 20 },
      { wch: 36 },
      { wch: 15 },
      { wch: 10 },
      { wch: 15 },
    ];
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "Enaam_users_list.xlsx");
  };
  if (loading)
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar onToggleSidebar={handleSidebarToggle} />
          <div
            className={`col ${
              isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
            } ms-auto`}
          >
            <div className="dashboard-content">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <div className="total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <h4 className="mb-0 fw-bold">
                  Total Users:{" "}
                  <span className="text-success fw-bold">
                    {sortedUsers.length}
                  </span>
                </h4>
              </div>
              <div className="d-flex gap-2 flex-wrap justify-content-start justify-content-md-end">
                <button className="btn btn-primary" onClick={downloadPdf}>
                  <bi className="bi bi-download"></bi> PDF
                </button>
                <button className="btn btn-success" onClick={downloadExcel}>
                  <bi className="bi bi-download"></bi> Excel
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-3">
              <input
                type="text"
                placeholder="Search users..."
                className="form-control mb-4 "
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <input
                type="date"
                className="form-control ms-2"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control ms-2"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div className="table-responsive">
              <table
                className="table table-hover table-striped table-bordered shadow-sm"
                style={{ fontSize: "14px" }}
              >
                <thead className="table-dark">
                  <tr>
                    <th>SR No.</th>
                    <th>Name</th>
                    <th>Referred Number</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Role</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {paginatedUsers.length ? (
                    paginatedUsers.map((user, index) => (
                      <tr key={user.id}>
                        <td className="align-middle">
                          {(currentPage - 1) * entriesPerPage + index + 1}
                        </td>
                        <td className="align-middle">{user.name}</td>
                        <td className="align-middle">
                          {user.promo_codes && user.promo_codes.length > 0
                            ? user.promo_codes.join(", ")
                            : "No Promocodes"}
                        </td>
                        <td className="align-middle">{user.email}</td>
                        <td className="align-middle">{user.phone}</td>
                        <td className="align-middle">{user.role}</td>
                        <td className="align-middle">
                          {new Date(user.created_at).toLocaleDateString()}
                        </td>
                        <td className="text-center align-middle">
                          <div className="d-flex gap-2 g-0 justify-content-center">
                            <Link
                              to={`/dashboard/users/${user.id}`}
                              className="btn btn-primary btn-sm"
                            >
                              <i className="bi bi-pencil"></i>
                            </Link>
                            {user?.role !== "admin" && (
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  setUserToDelete(user.id);
                                  setShowDeleteModal(true);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No users found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <Stack
              spacing={2}
              className="d-flex justify-content-center align-items-center mt-4"
            >
              <Pagination
                count={Math.ceil(sortedUsers.length / entriesPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                variant="outlined"
                color="primary"
              />
            </Stack>

            {showDeleteModal && (
              <div
                className="modal fade show"
                style={{ display: "block" }}
                tabIndex="-1"
                role="dialog"
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Confirm Deletion</h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowDeleteModal(false)}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to delete this user?</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setShowDeleteModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          handleDeleteUser(userToDelete);
                          setShowDeleteModal(false);
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AdminDashboard;
