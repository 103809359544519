

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import Loader from '../../Components/LoaderComponent';
import "../../css/Styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_URL } from '../../../Services';
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs/${slug}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        const blogData = response.data.blog;
        const seoData = response.data.seo_metadata ? response.data.seo_metadata : {};
        blogData.seo_metadata = seoData;
  
        setBlog(blogData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog details:', error);
        setLoading(false);
      }
    };
  
    fetchBlog();
  }, [slug]);
  

  if (loading) {
    return <Loader />;
  }

  if (!blog) {
    return <p>Blog not found</p>;
  }



  return (
    <div className='App'>
     <Helmet>
        <title>{blog.seo_metadata?.title || "Enaam.pk Blogs - Insights, Tips, and Updates"}</title>
        <meta name="description" content={blog.seo_metadata?.description || "Explore Enaam.pk blogs for insights on competitions, tips for winning prizes, and updates about our platform. Stay informed and inspired!"} />
        <link rel="canonical" href={blog.seo_metadata?.canonical || window.location.href} />
      </Helmet>
      <Header />
      <section className="blog-details-header inner-page py-5 mt-5">
        <div className="container-fluid col-lg-10  mt-5 p-0">
        
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="card glow" style={{ borderRadius: "15px" }}>
                <img 
                  src={blog.blog_image} 
                  alt={blog.heading} 
                  className="card-img-top" 
                  style={{ height: "auto", maxHeight: "500px", objectFit: "cover", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }} 
                />
          <h1  className="mb-4 text-md-center text-start ms-3 mt-3 blog-heading">{blog.heading}</h1>
        
                <div className="card-body p-4 text-start">
                <div className="blog-description rich-text-content" dangerouslySetInnerHTML={{ __html: blog.description }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BlogDetails;
