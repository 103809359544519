import React, { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import useMonthlyStats from "./Components/useMonthlyStats";

function MonthlyStatistics() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [search, setSearch] = useState("");
  const { monthlyStats, currentMonthData, loading } = useMonthlyStats();

  const filteredStats = monthlyStats.filter((monthlyStats) =>
    monthlyStats.month.toLowerCase().includes(search.toLowerCase())
  );

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-light rounded text-left">
                Monthly Statistics
              </h1>
            </div>
            <input
              type="text"
              placeholder="Search Month..."
              className="form-control mb-4"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />

            {loading ? (
              <div className="text-center">
                <div className="d-flex justify-content-center dashboard-loader align-items-center" style={{ height: "60vh" }}>
                  <Loader />
                </div>
              </div>
            ) : (
              <>
                {currentMonthData && (
                  <div className="table-responsive mb-4">
                    <table
                      className="table table-hover table-striped table-bordered shadow-sm rounded"
                      style={{ fontSize: "14px" }}
                    >
                      <thead className="table-primary">
                        <tr>
                          <th>{currentMonthData?.month}</th>
                          <th className="text-center">Total Users</th>
                          <th className="text-center">Total Participants</th>
                          <th className="text-center">Total Tickets</th>
                          <th className="text-center">Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-truncate align-middle">
                            {currentMonthData?.month}
                          </td>
                          <td className="text-truncate align-middle text-center">
                            {currentMonthData?.total_users}
                          </td>
                          <td className="text-truncate align-middle text-center">
                            {currentMonthData?.total_participants}
                          </td>
                          <td className="text-truncate align-middle text-center">
                            {currentMonthData?.total_quantity}
                          </td>
                          <td className="text-truncate align-middle text-center">
                            {currentMonthData?.total_amount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="table-responsive">
                  <table
                    className="table table-hover table-striped table-bordered shadow-sm rounded"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="table-dark">
                      <tr>
                        <th>Month</th>
                        <th className="text-center">Total Users</th>
                        <th className="text-center">Total Participants</th>
                        <th className="text-center">Total Tickets</th>
                        <th className="text-center">Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredStats.length ? (
                        filteredStats
                          .filter((item) => item.month !== currentMonthData?.month) 
                          .map((item, index) => (
                            <tr key={index}>
                              <td className="text-truncate align-middle">
                                {item.month}
                              </td>
                              <td className="text-truncate align-middle text-center">
                                {item.total_users}
                              </td>
                              <td className="text-truncate align-middle text-center">
                                {item.total_participants}
                              </td>
                              <td className="text-truncate align-middle text-center">
                                {item.total_quantity}
                              </td>
                              <td className="text-truncate align-middle text-center">
                                {item.total_amount}
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No data available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlyStatistics;
