import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../../Services/UserContext";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import ResendTicketMessage from "./Components/ResendTicketMessage";
import { Pagination } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import whatsappLogo from "../../UserSide/images/whatsapp_logo.png";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ParticipantsList = () => {
    const { getParticipants, participants, totalAmount } = useUser();
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [rewardFilterText, setRewardFilterText] = useState("");
    const [selectedReward, setSelectedReward] = useState("");
    const [amountFilter, setAmountFilter] = useState("");
    const [answerFilterText, setAnswerFilterText] = useState("");
    const [usernameFilterText, setUsernameFilterText] = useState("");
    const [sortOption, setSortOption] = useState("");
    const [uniqueRewards, setUniqueRewards] = useState([]);
    const [page, setPage] = useState(1);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentProcessingId, setCurrentProcessingId] = useState(null);
    const itemsPerPage = 10;
   

    useEffect(() => {
      setLoading(true);
      getParticipants()
          .then(() => setLoading(false)) 
          .catch((error) => {
              console.error("Error fetching participants:", error);
              setLoading(false);
          });
          const interval = setInterval(() => {
            getParticipants();
          }, 60000);
          return () => clearInterval(interval);
  }, []);

  useEffect(() => {
      if (Array.isArray(participants) && participants.length > 0) {
          setFilteredParticipants(participants);
          extractUniqueRewards(participants);
          setLoading(false);
      }
  }, [participants]);

  const extractUniqueRewards = (participants) => {
      const rewardsSet = new Set();
      participants.forEach((participant) => {
          const products = parseProducts(participant.processed_invoice?.products);
          products.forEach((product) => {
              if (product.reward && product.reward.name) {
                  rewardsSet.add(product.reward.name);
              }
          });
      });
      setUniqueRewards(Array.from(rewardsSet));
  };
  const convertTimeToSeconds = (timeString) => {
    if (!timeString) return 0;
    const [minutes, seconds] = timeString.split(":").map(Number);
    return minutes * 60 + seconds;
  };

useEffect(() => {
    if (!participants.length) return;
    const rewardText = rewardFilterText.toLowerCase();
    const answerText = answerFilterText.toLowerCase();
    const usernameText = usernameFilterText.toLowerCase();
    const startDateUTC = startDate ? new Date(`${startDate}T00:00:00Z`) : null;
    const endDateUTC = endDate ? new Date(`${endDate}T23:59:59Z`) : null;
  
    const filtered = participants.filter(({ processed_invoice, userNumber, created_at, total_amount }) => {
      const username = processed_invoice?.user?.name?.toLowerCase() || "";
      const userNum = userNumber?.toString() || "";
      const invoiceId = processed_invoice?.invoice_id?.toString()?.toLowerCase() || "";
      const participantDate = new Date(created_at);

      const hasRewardMatch = rewardText === "" || (processed_invoice?.products &&
          parseProducts(processed_invoice.products).some(product =>
            product.reward?.name.toLowerCase().includes(rewardText)
          ));

      const hasSelectedRewardMatch =
        selectedReward === "" ||
        (processed_invoice?.products &&
          parseProducts(processed_invoice.products).some(product =>
            product.reward?.name === selectedReward
          ));
  
      const hasAnswerMatch = answerText === "" || processed_invoice?.is_correct?.toLowerCase().includes(answerText);
  
      const hasUsernameMatch = 
        usernameText === "" || 
        username.includes(usernameText) || 
        userNum.includes(usernameText) || 
        invoiceId.includes(usernameText);
      const amountMatch = amountFilter === "" || (amountFilter === "greater" && total_amount > 0) ||(amountFilter === "less" && total_amount === 0);

      const dateMatch =(!startDateUTC || participantDate >= startDateUTC) && (!endDateUTC || participantDate <= endDateUTC);
  
      return hasRewardMatch && hasSelectedRewardMatch && hasAnswerMatch && hasUsernameMatch && amountMatch && dateMatch;
    });
  
    const sortedFilteredParticipants = sortOption
      ? [...filtered].sort((a, b) => {
          const timeA = a.processed_invoice ? convertTimeToSeconds(a.processed_invoice.answer_time) : 0;
          const timeB = b.processed_invoice ? convertTimeToSeconds(b.processed_invoice.answer_time) : 0;
          return sortOption === "asc" ? timeA - timeB : timeB - timeA;
        })
      : filtered;

    if (rewardFilterText || selectedReward || answerFilterText || usernameFilterText || amountFilter || startDate || endDate) {
      setPage(1);
    }
    setFilteredParticipants(sortedFilteredParticipants);
  }, [rewardFilterText, selectedReward, answerFilterText, usernameFilterText, participants, sortOption, amountFilter, startDate, endDate]);
  
  const downloadPDF = () => {
    const pdf = new jsPDF();
    const pageHeight = pdf.internal.pageSize.height;
    pdf.setFontSize(20);
    pdf.text("Participant Tickets List", 10, 20);
    pdf.setFontSize(12);
    let position = 30;
    const allRewards = new Set();

    const extractTickets = (participant) => {
      const processedInvoice = participant.processed_invoice || {};
      const tickets = processedInvoice.ticket_id || ["N/A"];
      const products = parseProducts(processedInvoice.products || []);

      const rewards = products.map(
        (product) => product.reward?.name || "No Reward"
      );
      let lastReward = "No Reward";

      return tickets.map((ticketID, index) => {
        lastReward = rewards[index] || lastReward;
        allRewards.add(lastReward);
        return { ticketID, reward: lastReward, participant };
      });
    };

    let allTickets = (filteredParticipants || []).flatMap(extractTickets);

    if (selectedReward) {
      allTickets = allTickets.filter((t) => t.reward === selectedReward);
    }

    const addTicketDetails = (ticketInfo, currentPosition) => {
      const { ticketID, reward, participant } = ticketInfo;
      const processedInvoice = participant.processed_invoice || {};
      const userName = String(processedInvoice.user?.name || "N/A");
      const invoiceId = String(processedInvoice.invoice_id || "N/A");
      const answer = String(processedInvoice.is_correct ?? "N/A");
      const email = String(processedInvoice.user?.email || "N/A");
      const userNumber = String(participant.userNumber || "N/A");
      const answerTime = processedInvoice.answer_time || 'N/A'; 

      pdf.setFont("helvetica", "bold");
      pdf.setTextColor(255, 0, 0);
      pdf.text(`Ticket ID: ${String(ticketID)}`, 10, currentPosition + 10);
      pdf.setTextColor(0, 0, 0);
      pdf.text("Date:", 130, currentPosition + 50);
      pdf.setFont("helvetica", "normal");
      pdf.text(
        new Date(participant.created_at || Date.now()).toLocaleDateString(),
        160,
        currentPosition + 50
      );
      pdf.setFont("helvetica", "bold");
      pdf.text("User Name:", 10, currentPosition + 20);
      pdf.setFont("helvetica", "normal");
      pdf.text(userName, 50, currentPosition + 20);

      pdf.setFont("helvetica", "bold");
      pdf.text("Answer Time:", 130, position + 20);
      pdf.setFont("helvetica", "normal");
      pdf.text(answerTime, 160, position + 20);

      pdf.setFont("helvetica", "bold");
      pdf.text("Invoice ID:", 130, currentPosition + 10);
      pdf.setFont("helvetica", "normal");
      pdf.text(invoiceId, 160, currentPosition + 10);

      pdf.setFont("helvetica", "bold");
      pdf.text("Number:", 10, currentPosition + 30);
      pdf.setFont("helvetica", "normal");
      pdf.text(userNumber, 50, currentPosition + 30);

      pdf.setFont("helvetica", "bold");
      pdf.text("Answer:", 130, currentPosition + 30);
      pdf.setFont("helvetica", "normal");
      pdf.text(answer, 160, currentPosition + 30);

      pdf.setFont("helvetica", "bold");
      pdf.text("Email:", 10, currentPosition + 40);
      pdf.setFont("helvetica", "normal");
      pdf.text(email, 50, currentPosition + 40);

      pdf.setFont("helvetica", "bold");
      pdf.text("Reward:", 10, currentPosition + 50);
      pdf.setFont("helvetica", "normal");
      pdf.text(String(reward), 50, currentPosition + 50);

      pdf.setFont("helvetica", "bold");
      pdf.text("Amount:", 130, currentPosition + 40);
      pdf.setFont("helvetica", "normal");
      pdf.text(
        Number(participant.total_amount || 0).toFixed(2),
        160,
        currentPosition + 40
      );

      const dividerY = currentPosition + 60;
      pdf.line(10, dividerY, 200, dividerY);

      currentPosition += 70;

      if (currentPosition > pageHeight - 20) {
        pdf.addPage();
        currentPosition = 15;
      }

      return currentPosition;
    };

    allTickets.forEach((ticketInfo) => {
      position = addTicketDetails(ticketInfo, position);
    });

    let filename;
    if (selectedReward) {
      filename = `Tickets-list(${selectedReward.replace(/\s+/g, "_")}).pdf`;
    } else {
      const rewardNames = Array.from(allRewards)
        .join("-")
        .replace(/:/g, "")
        .replace(/\s+/g, "_");
      filename = `Tickets-list(${rewardNames}).pdf`;
    }
    pdf.save(filename);
  };

  const downloadExcel = () => {
    const allRewards = new Set();
    const paidParticipants = filteredParticipants.filter(
      (participant) => participant.total_amount > 0
    );
    const unpaidParticipants = filteredParticipants.filter(
      (participant) => participant.total_amount <= 0
    );
    const processParticipantDetails = (participant) => {
      const processedInvoice = participant.processed_invoice || {};
      const userName = processedInvoice.user
        ? processedInvoice.user.name
        : "N/A";
      const invoiceId = processedInvoice.invoice_id || "N/A";
      const answer = processedInvoice.is_correct || "N/A";
      const email = processedInvoice.user ? processedInvoice.user.email : "N/A";
      const userNumber = participant.userNumber;
      const answerTime = processedInvoice.answer_time || "N/A";
      const tickets = processedInvoice.ticket_id || ["N/A"];
      const rewards = processedInvoice.products
        ? parseProducts(processedInvoice.products).map((product) =>
            product.reward ? product.reward.name : "N/A"
          )
        : ["N/A"];

      rewards.forEach((reward) => allRewards.add(reward));
      const ticketRewards = {};
      let lastReward = "No Reward";

      if (selectedReward) {
        const filteredRewards = rewards.filter(
          (reward) => reward === selectedReward
        );
        const filteredTickets = tickets.filter(
          (_, index) => filteredRewards[index] === selectedReward
        );
        filteredTickets.forEach((ticketID, index) => {
          lastReward = filteredRewards[index] || "No Reward";
          ticketRewards[ticketID] = lastReward;
        });
        tickets.length = 0;
        tickets.push(...filteredTickets);
      } else {
        tickets.forEach((ticketID, index) => {
          lastReward = rewards[index] || "No Reward";
          ticketRewards[ticketID] = lastReward;
        });
      }

      return tickets.map((ticketID) => ({
        "SR No.": null,
        "Ticket ID": ticketID,
        "User Name": userName,
        "Invoice ID": invoiceId,
        Number: userNumber,
        Answer: answer,
        Email: email,
        "Answer Time": answerTime,
        Reward: ticketRewards[ticketID] || "No Reward",
        Amount: Number(participant.total_amount).toFixed(2),
        Date: new Date(participant.created_at).toLocaleDateString(),
      }));
    };

    const data = [];
    paidParticipants.forEach((participant) => {
      data.push(...processParticipantDetails(participant));
    });
    unpaidParticipants.forEach((participant) => {
      data.push(...processParticipantDetails(participant));
    });

    data.forEach((row, index) => {
      row["SR No."] = index + 1;
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const columnWidths = [
      { wch: 10 },
      { wch: 15 },
      { wch: 25 },
      { wch: 12 },
      { wch: 15 },
      { wch: 10 },
      { wch: 30 },
      { wch: 10 },
      { wch: 15 },
      { wch: 18 },
      { wch: 15 },
    ];
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
    let filename;
    if (selectedReward) {
      filename = `Tickets-list(${selectedReward.replace(/\s+/g, "_")}).xlsx`;
    } else {
      const rewardNames = Array.from(allRewards)
        .join("-")
        .replace(/:/g, "")
        .replace(/\s+/g, "_");
      filename = `Tickets-list(${rewardNames}).xlsx`;
    }
    XLSX.writeFile(workbook, filename);
  };
  const parseProducts = (products) => {
    if (!products) return [];

    if (typeof products === "string") {
      try {
        const parsed = JSON.parse(products);
        return Array.isArray(parsed) ? parsed : Object.values(parsed);
      } catch (error) {
        console.error("Error parsing products JSON:", error);
        return [];
      }
    }

    return Array.isArray(products) ? products : Object.values(products);
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const displayedParticipants = filteredParticipants.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  if (loading)
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar onToggleSidebar={handleSidebarToggle} />
          <div
            className={`col ${
              isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
            } ms-auto`}
          >
            <div className="dashboard-content">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <Loader />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <div className=" total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <h4 className="mb-0 fw-bold">
                  Participants: <span className="text-success fw-bold">{filteredParticipants.length}</span>
                </h4>
              </div>
              <div className="d-flex gap-2 flex-wrap justify-content-center justify-content-md-end">
                <h5 className="text-dark shadow-sm p-1 px-2 bg-body rounded border">
                  Sales:{" "}
                  <span className="text-success fw-bold">
                    Rs. {totalAmount.toFixed(2)}
                  </span>
                </h5>
                <button className="btn btn-primary" onClick={downloadPDF}>
                  <bi className="bi bi-download"></bi> PDF
                </button>
                <button className="btn btn-success" onClick={downloadExcel}>
                  <bi className="bi bi-download"></bi> Excel
                </button>
              </div>
            </div>

            <div className="d-flex justify-content-center mb-3">
              <input
                type="text"
                placeholder="Filter User"
                className="form-control me-2"
                value={usernameFilterText}
                onChange={(e) => setUsernameFilterText(e.target.value)}
              />

              <select
                className="form-control"
                value={selectedReward}
                onChange={(e) => setSelectedReward(e.target.value)}
              >
                <option value="">Select Reward</option>
                {uniqueRewards.map((reward, index) => (
                  <option key={index} value={reward}>
                    {reward}
                  </option>
                ))}
              </select>
              <select
                className="form-control ms-2"
                value={amountFilter}
                onChange={(e) => setAmountFilter(e.target.value)}
              >
                <option value="">All Amount</option>
                <option value="greater">Paid</option>
                <option value="less">Unpaid</option>
              </select>
              <select
                className="form-control ms-2"
                value={answerFilterText}
                onChange={(e) => setAnswerFilterText(e.target.value)}
              >
                <option value="">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
              <select
                className="form-control ms-2"
                value={sortOption}
                onChange={(e) => setSortOption(e.target.value)}
              >
                <option value="">Time</option>
                <option value="asc">Slowest Time</option>
                <option value="desc">Fastest Time</option>
              </select>
              <input
                type="date"
                className="form-control ms-2"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control ms-2"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>

            <div className="table-responsive">
              {displayedParticipants.length > 0 ? (
                <>
                  <table
                    className="table table-hover table-striped table-bordered shadow-sm rounded"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="table-dark">
                      <tr>
                        <th>Sr</th>
                        <th className="col-2">Username</th>
                        <th>Invoice ID</th>
                        <th>Amount</th>
                        <th>Number</th>
                        <th>Products</th>
                        <th className="col-1 text-center">Ticket IDs</th>
                        <th>Answer</th>
                        <th className="col-1 text-center">Answer Time</th>
                        <th className="col-1 text-center">Platform</th>
                        <th className="col-1 text-center">Date</th>
                        <th className="col-1 text-center">Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedParticipants.map((participant, index) => (
                        <tr key={participant.id}>
                          <td className="align-middle">
                            {(page - 1) * itemsPerPage + index + 1}
                          </td>
                          <td className="align-middle">
                            {participant.processed_invoice &&
                            participant.processed_invoice.user
                              ? participant.processed_invoice.user.name
                              : "N/A"}
                          </td>
                          <td className="align-middle">
                            {participant.processed_invoice
                              ? participant.processed_invoice.invoice_id
                              : "N/A"}
                          </td>

                          <td className="align-middle">
                            {Number(participant.total_amount).toFixed(2)}
                          </td>
                          <td className="align-middle">
                            {participant.userNumber}
                          </td>
                          <td className="align-middle">
                            {parseProducts(
                              participant?.processed_invoice?.products
                            )
                              .filter(
                                (product) =>
                                  !selectedReward ||
                                  product.reward?.name === selectedReward
                              )
                              .map((product, index) => (
                                <div
                                  className="d-flex justify-content-between"
                                  key={index}
                                >
                                  <div>
                                    <p className="text-dark">{product.name}</p>
                                  </div>
                                </div>
                              ))}
                          </td>
                          <td className="align-middle">
                            <p className="text-dark">
                              {Array.isArray(
                                participant?.processed_invoice?.ticket_id
                              )
                                ? (() => {
                                    const products = parseProducts(
                                      participant?.processed_invoice?.products
                                    );
                                    if (!selectedReward)
                                      return participant.processed_invoice.ticket_id.join(
                                        ", "
                                      );
                                    const totalTicketsNeeded = products
                                      .filter(
                                        (product) =>
                                          product.reward?.name ===
                                          selectedReward
                                      )
                                      .reduce(
                                        (count, product) =>
                                          count + (product.quantity || 1),
                                        0
                                      );

                                    if (totalTicketsNeeded === 0) return "N/A";

                                    return participant.processed_invoice.ticket_id
                                      .slice(0, totalTicketsNeeded)
                                      .join(", ");
                                  })()
                                : participant?.processed_invoice?.ticket_id ||
                                  "N/A"}
                            </p>
                          </td>

                          <td className="align-middle text-center">
                            {participant.processed_invoice
                              ? participant.processed_invoice.is_correct
                              : "N/A"}
                          </td>
                          <td className="align-middle text-center">
                            {participant.processed_invoice
                              ? participant.processed_invoice.answer_time
                              : "N/A"}
                          </td>
                          <td className="align-middle text-center">{participant.processed_invoice?.platform || "N/A"}</td>
                          <td className="align-middle text-center">
                            {new Date(
                              participant.created_at
                            ).toLocaleDateString() || "N/A"}
                          </td>
                          <td className="align-middle text-center">
                            <div className="d-flex gap-2 g-0 justify-content-center">
                              <Link
                                to={`/dashboard/participants/${participant.id}`}
                                className="btn btn-info btn-sm"
                              >
                                <i className="bi bi-eye"></i>
                              </Link>
                              <ResendTicketMessage
                                userId={ participant.processed_invoice?.user?.id ||  "N/A" }
                                ticketIds={Array.isArray(participant.processed_invoice?.ticket_id) ? participant.processed_invoice.ticket_id.join(  ", " ) : participant.processed_invoice  ?.ticket_id || "N/A" }
                                currentProcessingId={currentProcessingId}
                                setCurrentProcessingId={setCurrentProcessingId}/>

                              <img
                                src={whatsappLogo}
                                style={{ cursor: "pointer",width: 32, height: 32, justifyContent: "center",alignSelf: "flex-end", }}
                                onClick={() => {
                                  let phoneNumber = participant.userNumber;
                                  if (phoneNumber.startsWith("0")) {
                                    phoneNumber = "92" + phoneNumber.slice(1);
                                  }
                                  const message = encodeURIComponent(
                                    "Thank you for joining Enaam.pk! Ready to turn your dreams into reality? Join Enaam.pk now for a chance to win amazing rewards!"
                                  );
                                  const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
                                  window.open(whatsappURL, "_blank");
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      count={Math.ceil(
                        filteredParticipants.length / itemsPerPage
                      )}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      variant="outlined"
                    />
                  </div>
                </>
              ) : (
                <p className="text-center fw-bold text-danger">
                  No participants found.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default ParticipantsList;
