// Loader.js
import React from 'react';
import '../css/Styles.css';

const Loader = () => (
  <div className="loader-container d-flex justify-content-center align-items-center">
    <div className="loader"></div>
  </div>
);

export default Loader;
