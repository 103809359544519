import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../Services";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import Pagination from "@mui/material/Pagination";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const InActiveUsers = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [inactiveUsers, setInActiveUsers] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const itemsPerPage = 10;

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
  useEffect(() => {
    const fetchInActiveUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/delete-users`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setInActiveUsers(response.data);
      } catch (error) {
        console.error("Error fetching Inactive users:", error);
        toast.error("Failed to fetch Inactive users.");
      } finally {
        setLoading(false);
      }
    };
    fetchInActiveUsers();
  }, []);
  const filteredUsers = inactiveUsers
    ? inactiveUsers.filter((user) => {
        const userDeletedAt = user.deleted_at
          ? new Date(user.deleted_at)
          : null;
        const dateMatch =
          (!startDate || userDeletedAt >= new Date(`${startDate}T00:00:00Z`)) &&
          (!endDate || userDeletedAt <= new Date(`${endDate}T23:59:59Z`));

        const textMatch =
        (user.name &&
          user.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.email &&
          user.email.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (user.phone && user.phone.includes(searchTerm));

        return textMatch && dateMatch;
      })
    : [];

  const totalFilteredUsers = filteredUsers.length;

  const sortedFilteredUsers = filteredUsers.sort(
    (a, b) => new Date(b.deleted_at) - new Date(a.deleted_at)
  );
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedUsers = sortedFilteredUsers.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    const userRows = sortedFilteredUsers
      .filter((user) => user.role === "user")
      .map((user, index) => [
        index + 1,
        user.name,
        user.email,
        user.phone,
        new Date(user.deleted_at).toLocaleDateString(),
      ]);

    doc.autoTable({
      head: [["SR No.", "Name", "Email", "Phone", "Deleted at"]],
      body: userRows,
    });
    doc.save("Enaam_Deleted_users_list.pdf");
  };
  const downloadExcel = () => {
    const userRows = sortedFilteredUsers
      .filter((user) => user.role === "user")
      .map((user, index) => ({
        "SR No.": index + 1,
        Name: user.name,
        Email: user.email,
        Phone: user.phone,
        Deleted_at: new Date(user.deleted_at).toLocaleDateString(),
      }));
    const worksheet = XLSX.utils.json_to_sheet(userRows);
    const columnWidths = [
      { wch: 8 },
      { wch: 20 },
      { wch: 36 },
      { wch: 15 },
      { wch: 15 },
    ];
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
    XLSX.writeFile(workbook, "Enaam_users_list.xlsx");
  };

  return (
    <div className="container-fluid ">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <div className="total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
              <h4 className="fw-bold text-dark">
                Inactive Users:{" "}
                <span className="text-success">{totalFilteredUsers}</span>
              </h4>
              <div className="d-flex gap-2 flex-wrap justify-content-start justify-content-md-end">
                <button className="btn btn-primary" onClick={downloadPdf}>
                  <bi className="bi bi-download"></bi> PDF
                </button>
                <button className="btn btn-success" onClick={downloadExcel}>
                  <bi className="bi bi-download"></bi> Excel
                </button>
              </div>
            </div>
            <div className="d-flex mb-4 justify-content-between align-items-center mt-4">
              <input
                type="text"
                placeholder="Search inactive users..."
                className="form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <input
                type="date"
                className="form-control ms-2"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control ms-2"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "60vh" }}
              >
                <Loader />
              </div>
            ) : (
              <>
                <div className="table-responsive">
                  <table
                    className="table table-hover table-striped table-bordered shadow-sm"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="table-dark">
                      <tr>
                        <th>SR No.</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th className="text-center">Created at</th>
                        <th className="text-center">Delete at</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedUsers.length ? (
                        paginatedUsers.map((user, index) => (
                          <tr key={user.id}>
                            <td className="align-middle">
                              {startIndex + index + 1}
                            </td>
                            <td className="align-middle">{user.name}</td>
                            <td className="align-middle">{user.email}</td>
                            <td className="align-middle">{user.phone}</td>

                            <td className="text-center align-middle">
                              {new Date(user.created_at).toLocaleDateString()}
                            </td>
                            <td className="text-center align-middle">
                              {new Date(user.deleted_at).toLocaleDateString()}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No inactive users found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            <div className="d-flex justify-content-center mt-3">
              <Pagination
                count={Math.ceil(totalFilteredUsers / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                variant="outlined"
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InActiveUsers;
