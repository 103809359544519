
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";

function ProductsList() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/products`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        if (response.data.status === 200) {
          setProducts(response.data.products);
        } else {
          console.error("Failed to fetch products");
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [userData]);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={setIsSidebarOpen} />
        <div className={`col ${isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"} ms-auto`}>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "70vh" }}>
              <Loader />
            </div>
          ) : (
            <DynamicTable
              title="Products List"
              headers={[
                { id: "name", label: "Name" },
                { id: "price", label: "Price" },
                { id: "quantity", label: "Quantity" },
                { id: "description", label: "Description" },
                { id: "reward", label: "Reward", accessor: (row) => row.reward?.name || "N/A" },
                { id: "image", label: "Image", type: "image" },
              ]}
              data={products}
              onEdit={(id) => navigate(`/dashboard/products/${id}/edit`)}
              onDelete={async (id, closeModal) => {
                await axios.delete(`${BASE_URL}/products/${id}`, { headers: { Authorization: `Bearer ${userData?.token}` } });
                setProducts(products.filter((p) => p.id !== id));
                toast.success("Product deleted successfully!");
                closeModal(false);
              }}
              onView={(id) => navigate(`/dashboard/products/${id}`)}
              onAdd={() => navigate("/dashboard/products/create")}
              showViewButton={true}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ProductsList;

