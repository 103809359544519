
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import "react-toastify/dist/ReactToastify.css";

function RewardsList() {
  const [rewards, setRewards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/rewards`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        if (
          response.data.status === 200 &&
          Array.isArray(response.data.rewards)
        ) {
          setRewards(response.data.rewards);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching rewards:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRewards();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/dashboard/rewards/${id}/edit`);
  };

  const handleDeleteClick = async (id, closeModal) => {
    try {
      await axios.delete(`${BASE_URL}/rewards/${id}`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      setRewards(rewards.filter((reward) => reward.id !== id));
      toast.success("Reward deleted successfully!");
      closeModal(false);
    } catch (error) {
      console.error("Error deleting reward:", error);
    }
  };

  const handleAddClick = () => {
    navigate("/dashboard/rewards/create");
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <Loader />
            </div>
          ) : (
            <DynamicTable
              title="Rewards List"
              headers={[
                { id: "name", label: "Name" },
                { id: "description", label: "Description" },
                { id: "image", label: "Image", type: "image" },
              ]}
              data={rewards}
              onEdit={handleEditClick}
              onDelete={handleDeleteClick}
              onAdd={handleAddClick}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default RewardsList;
