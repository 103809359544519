import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useParams, Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify'; 
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

import Sidebar from '../sidebar/Sidebar'; 
import Loader from '../../UserSide/Components/LoaderComponent'; 
import 'react-toastify/dist/ReactToastify.css';

function ProductDetail() {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
    const { userData } = useUser();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/products/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`
                    }
                });

                if (response.status === 200) {
                    setProduct(response.data);
                } else {
                    toast.error('Failed to fetch product details.');
                }
            } catch (error) {
                console.error('Error fetching product details:', error.response ? error.response.data : error.message);
                toast.error('Error fetching product details.');
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Loader /> 
            </div>
        );
    }

    if (!product) {
        return <p className="text-center text-danger">No product found</p>;
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>

                    <div className="dashboard-content p-4">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow p-3 mb-2 bg-light rounded">
                                Product Detail
                            </h1>
                            <Link to="/dashboard/products" className="btn btn-secondary shadow-sm">
                                <i className="bi bi-arrow-left me-2"></i> Back to Products List
                            </Link>
                        </div>

                        <div className="card shadow-lg border-0 rounded">
                            <div className="card-body">
                                <div className="row">
                                    
                                    <div className="col-md-8">
                                        <h2 className=" mb-3" style={{color:'#097dc4'}}>{product.name}</h2>
                                        <div className="mt-3 mb-3">
                                            <img
                                                src={product.image}
                                                alt={product.name}
                                                className="rounded shadow"
                                                style={{ maxWidth: '80%', height: '100px' }}
                                            />
                                         </div>
                                        <div className="mb-3 mt-4">
                                            <p><strong>ID:</strong> {product.id}</p>
                                            <p><strong>Price:</strong> Rs.{product.price}</p>
                                            <p><strong>Quantity:</strong> {product.quantity}</p>
                                            <p><strong>Sold:</strong> {product.sold}</p>
                                            <p><strong>Stock:</strong> {product.stock}</p>
                                            <p><strong>Description:</strong> {product.description}</p>
                                            <p><strong>Reward:</strong> {product.reward ? product.reward.name : 'N/A'}</p>
                                        </div>
                                        {product.reward && product.reward.image && (
                                            <div>
                                                <p><strong>Reward Image:</strong></p>
                                                <img
                                                    src={product.reward.image}
                                                    alt={product.reward.name}
                                                    className="img-fluid rounded shadow"
                                                    style={{ maxWidth: '200px', height: 'auto' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
