import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../Services/UserContext";
import { BASE_URL } from "../../Services";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import "react-toastify/dist/ReactToastify.css";

const ContactList = () => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const { userData } = useUser();

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/contacts`, {
                    headers: { Authorization: `Bearer ${userData?.token}` },
                });
                const sortedContacts = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                const transformedContacts = sortedContacts.map((contact) => ({
                    ...contact,
                    created_at: new Date(contact.created_at).toISOString().split("T")[0],   
                }))
                setContacts(transformedContacts);
            } catch (error) {
                console.error("Error fetching contacts:", error);
                toast.error(`Error fetching contacts: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchContacts();
    }, []);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"} ms-auto`}>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                            <Loader />
                        </div>
                    ) : (
                        <DynamicTable
                            title="Contact List"
                            headers={[
                                { id: "username", label: "Username" },
                                { id: "email", label: "Email" },
                                { id: "message", label: "Message" },
                                { id: "created_at", label: "Date" }
                            ]}
                            data={contacts}
                            hideActions={true}
                        />
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default ContactList;
