import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../Services/UserContext.js';

const PrivateRoute = ({ element: Component,allowedRoles, ...rest }) => {
    const { userData } = useUser()
    const location = useLocation();
    const screenList = {
        admin: [
          { title: "Users", nav: "/dashboard", icon: "bi-house-door" },
          { title: "Stats & Insights", nav: "/dashboard/stats", icon: "bi bi-bar-chart" },
          { title: "Monthly Stats", nav: "/dashboard/monthly-stats", icon: "bi bi-calendar-check" },
          { title: "Participants", nav: "/dashboard/participants", icon: "bi bi-people" },
          { title: "Ambassadors", nav: "/dashboard/ambassadors", icon: "bi bi-people-fill" },
          { title: "Tickets", nav: "/dashboard/invoices", icon: "bi bi-receipt" },
          { title: "Offers", nav: "/dashboard/offers", icon: "bi bi-gift" },
          { title: "Rewards", nav: "/dashboard/rewards", icon: "bi bi-trophy" },
          { title: "Winners", nav: "/dashboard/winners", icon: "bi bi-trophy-fill" },
          { title: "Products", nav: "/dashboard/products", icon: "bi bi-box" },
          { title: "Banners", nav: "/dashboard/banners", icon: "bi bi-image" },
          { title: "Videos", nav: "/dashboard/videos", icon: "bi bi-film" },
          { title: "Blogs", nav: "/dashboard/blogs", icon: "bi bi-journal-text" },
          { title: "FAQs", nav: "/dashboard/faqs", icon: "bi bi-question" },
          { title: "Questions", nav: "/dashboard/questions", icon: "bi bi-question-circle" },
          { title: "About Us", nav: "/dashboard/about-us", icon: "bi bi-info-circle" },
          { title: "Contacts", nav: "/dashboard/contacts", icon: "bi bi-telephone" },
        ],
        editor: [
          { title: "Stats & Insights", nav: "/dashboard/stats", icon: "bi bi-bar-chart" },
          { title: "Monthly Stats", nav: "/dashboard/monthly-stats", icon: "bi bi-calendar-check" },
          { title: "Offers", nav: "/dashboard/offers", icon: "bi bi-gift" },
          { title: "Rewards", nav: "/dashboard/rewards", icon: "bi bi-trophy" },
          { title: "Banners", nav: "/dashboard/banners", icon: "bi bi-image" },
          { title: "Winners", nav: "/dashboard/winners", icon: "bi bi-trophy-fill" },
          { title: "Products", nav: "/dashboard/products", icon: "bi bi-box" },
          { title: "Videos", nav: "/dashboard/videos", icon: "bi bi-film" },
          { title: "Blogs", nav: "/dashboard/blogs", icon: "bi bi-journal-text" },
          { title: "FAQs", nav: "/dashboard/faqs", icon: "bi bi-question" },
          { title: "Questions", nav: "/dashboard/questions", icon: "bi bi-question-circle" },
          { title: "About Us", nav: "/dashboard/about-us", icon: "bi bi-info-circle" },
        ],
        viewer: [
          { title: "Stats & Insights", nav: "/dashboard/stats", icon: "bi bi-bar-chart" },
          { title: "Monthly Stats", nav: "/dashboard/monthly-stats", icon: "bi bi-calendar-check" },
        ],
      };
    // console.log("userRole", userData?.role);

    if (userData?.token==="") {
        return <Navigate to="/" state={{ from: location }} />;
    }

    
    switch (userData?.role) {
     case 'admin':
        return <Component />;
     case 'editor':
        return screenList[userData?.role]?.some(item => location.pathname.startsWith(item.nav)) 
        ? <Component /> 
        : <Navigate to="/dashboard/stats" state={{ from: location }} />;
        
          case 'viewer':
        return <Navigate to="/dashboard/stats" state={{ from: location }} />;      
     case 'user':
        return <Navigate to="/" />;      
     default:
        return <Navigate to="/dashboard" state={{ from: location }} />;
    }



};

export default PrivateRoute;
