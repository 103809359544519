import React from "react";

export const WinnerComponent = ({ winner, data, index }) => {
  return (
    <div key={index} className="mb-3 col-lg-4 mt-1 col-md-4 p-0 p-md-2 p-lg-2">
      <div
        className="glow mb-3 glow-card"
        style={{
          backgroundColor: "white",
          borderRadius: "15px",
          height: "435px",
        }}
      >
        <img
          className="img-fluid"
          style={{ borderRadius: "15px", height: "230px", width: "100%" }}
          src={winner?.image}
          alt=" "
        />
        <h4>
          <a href="" className="title-head text-danger">
            Congratulations
          </a>
        </h4>
        <h4 className="title-text fw-bold" style={{ color: "#3789FF" }}>
          {winner?.participant?.processed_invoice?.user?.name}
        </h4>
        <p style={{ display: "block" }}>
          on Winning <b style={{ color: "black" }}>{winner?.reward?.name}</b>
        </p>
        <p>Ticket no: <span className="fw-bold text-dark">{winner?.ticket_id}</span> </p>
        <p style={{ fontSize: "15px" }}>Announced on {data}</p>
      </div>
    </div>
  );
};
