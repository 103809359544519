import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";

import Header from "../../Components/HeaderComponent";
import Footer from "../../Components/FooterCompnent";
import Loader from "../../Components/LoaderComponent";

import { Colors } from "../../globals/colors";
import { BASE_URL } from "../../../Services";
import { useUser } from "../../../Services/UserContext";

import "../../css/ProductList.css";
import "react-toastify/dist/ReactToastify.css";
import "../../css/ProductList.css";
import CustomModal from "../../Components/CustomModal";
import Login from "../../../Dashboard/Login";
import Register from "../../../Dashboard/Register";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();
  const [isModalOpen, setModalOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const toggleComponent = () => {
    setShowLogin(!showLogin);
  };
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/products/${id}`);
        setProduct(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  const addProductToCart = async () => {
    try {
      if (!userData?.token) {
        setModalOpen(true);
        return;
      }
      const response = await axios.post(
        `${BASE_URL}/cart/add`,
        {
          product_id: product.id,
          quantity: 1,
          price: product.price * 1.16,
        },
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      toast.success("Product added to cart");
      navigate("/cart");
    } catch (error) {
      console.error("Error adding product to cart:", error);
      if (error.response) {
        if (error.response.status === 401) {
          toast.error("Please login to add product to cart");
          navigate("/dashboard/login");
        } else {
          toast.error(
            `Failed to add product to cart: ${error.response.data.message}`
          );
        }
      } else if (error.request) {
        toast?.error("No response from the server. Please try again later.");
      } else {
        toast.error(`Error: ${error.message}`);
      }
    }
  };

  if (loading) return <Loader />;
  if (!product) return <p>Product not found.</p>;

  const sold = product.sold;
  const quantity = product.quantity;

  const additionalPercentage = (sold / quantity) * 100;
  const percentage = Math.min(
    additionalPercentage >= 50
      ? additionalPercentage
      : additionalPercentage + 50,
    100
  );
  const setProductsPercentage = parseFloat(percentage.toFixed(1));

  let progressBarClass = "progress-green";
  let buttonStyle = {};
  let buttonText = "Add to Cart";
  let drawDateSection = null;

  if (percentage >= 70 && percentage < 90) {
    progressBarClass = "progress-orange";
  } else if (percentage >= 90 && percentage < 100) {
    progressBarClass = "progress-red";
  } else if (percentage === 100) {
    progressBarClass = "progress-red";
    // buttonStyle = { pointerEvents: "none", opacity: 0.6 };
    // buttonText = "Entries Completed";
  }

  if (percentage === 100 && product.draw_date) {
    drawDateSection = (
      <div>
        <img src="/path/to/calendar.png" alt="" />
        <a href="">
          Max draw date: <br />
          {new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
          }).format(new Date(product.draw_date))}
        </a>
      </div>
    );
  }

  return (
    <div className="container-fluid mt-5 p-0">
      <Header />

      <div className="container-fluid mt-5 mb-5 col-lg-11 col-11 p-2 product-details ">
        <br></br>
        <div className="row mt-5 d-flex justify-content-between">
          <div className="col-md-8 col-lg-8 mt-5">
            <div className="sub-info glow">
              <div className="p-2 mt-2">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h4
                    style={{ margin: 0, textAlign: "left", fontWeight: "bold" }}
                  >
                    Reward :{" "}
                    <span className="fw-bold" style={{ color: "#3789FF" }}>
                      {product.reward.name}
                    </span>
                  </h4>
                  <div>
                    {drawDateSection}
                    <div
                      className="product-detail-state d-none d-lg-block d-md-block"
                      style={{ width: "170px" }}
                    >
                      <h6 className="mb-1 card-heading">
                        <p>
                          {setProductsPercentage}% Entries out of {"100%"}
                        </p>
                      </h6>
                      <div className="progress">
                        <div
                          className={progressBarClass}
                          role="progressbar"
                          style={{ width: `${percentage}%` }}
                          aria-valuenow={percentage}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row chunking-wrapper mt-3">
                  {/* <div className="col-md-6">
                                        <div className="detail-chunk ">
                                            <div className="detail-img mb-md-4 mb-lg-0 ">
                                                <img src={product.image} style={{ borderRadius: "20px" }} alt={product.name} />
                                            </div>
                                            <h4 style={{ marginTop: "10px" }}></h4>
                                        </div>
                                    </div> */}

                  <div className="col-md-4">
                    <div className="detail-chunk">
                      <div className="detail-img">
                        <img
                          src={product.reward.image}
                          style={{ borderRadius: "20px" }}
                          alt={product.reward.name}
                        />
                      </div>

                      {/* <h4 style={{ marginTop: "10px" }}>Get a chance to win:</h4> */}
                      {/* <p style={{ lineHeight: '1',marginTop: "10px"  }} className='fw-bold'>{product.reward.name}</p> */}
                    </div>
                  </div>
                  <div className="product-description col-md-8">
                    <h4
                      style={{
                        textAlign: "left",
                        color: "#007bff",
                        fontWeight: "bold",
                      }}
                    >
                      Prize Details
                    </h4>
                    <p
                      className="d-flex justify-content-start"
                      style={{ fontSize: "15px" }}
                    >
                      {product.reward.description}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-lg-3 mt-5">
            <div className="price-section">
              <div>
                <h2 className="price-heading">Price</h2>
                <span className="inclusive">Exclusive of tax</span>
              </div>
              <div className="value-price">RS. {product.price}</div>
            </div>
            <div className="cart-btn-wrap">
              <a
                className="btn btn-primary cart-btn"
                style={{ color: "white", ...buttonStyle }}
                onClick={addProductToCart}
              >
                {buttonText}
              </a>
            </div>
            <div className="compaign-btn">
              <button
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/sharer/sharer.php?u=https://example.com/",
                    "facebook-share-dialog",
                    "width=800,height=600"
                  )
                }
              >
                <img
                  src="/path/to/compaign-btn.png"
                  alt=""
                  style={{ cursor: "pointer", maxWidth: "100%" }}
                />
              </button>
            </div>
          </div>
        </div>
        <CustomModal isOpen={isModalOpen} onClose={handleModalClose}>
          <div>
            {showLogin ? <Login /> : <Register />}
            <button
              onClick={toggleComponent}
              className="btn btn-link mt-2 "
              style={{
                cursor: "pointer",
                color: Colors.themeColor,
                fontWeight: "bold",
                fontSize: "14px",
                textDecoration: "none",
              }}
            >
              {showLogin ? (
                "Don't have an account?"
              ) : (
                <>
                  <span style={{ color: "#343434", fontWeight: "400" }}>
                    Already have an account?
                  </span>{" "}
                  Go to Login
                </>
              )}
            </button>
          </div>
        </CustomModal>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default ProductDetails;
