import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";

function EditBlog() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const [formData, setFormData] = useState({
    slug: "",
    heading: "",
    description: "",
    blog_image: null,
  });
  const [seoMetadata, setSeoMetadata] = useState({
    title: "",
    description: "",
    canonical: "",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const { userData } = useUser();

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "link",
    "image",
  ];

  useEffect(() => {
    const fetchBlog = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${BASE_URL}/blogs/${slug}`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });

        const blogData = response.data.blog;
        const seoData = response.data.seo_metadata || {};

        setBlog(blogData);
        setSeoMetadata(seoData);

        setFormData({
          slug: blogData.slug,
          heading: blogData.heading,
          description: blogData.description,
          blog_image: null,
        });
      } catch (error) {
        console.error("Error fetching blog:", error);
        toast.error("Failed to fetch blog.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  const handleQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (["title", "description", "canonical"].includes(name)) {
      setSeoMetadata({ ...seoMetadata, [name]: value });
    } else {
      setFormData({
        ...formData,
        [name]: files ? files[0] : value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(
        `${BASE_URL}/blogs/${slug}`,
        {
          slug: formData.slug,
          heading: formData.heading,
          description: formData.description,
          seo_metadata: seoMetadata,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      if (formData.blog_image) {
        const fileFormData = new FormData();
        fileFormData.append("blog_image", formData.blog_image);

        await axios.post(
          `${BASE_URL}/blogs/${blog.id}/upload-image`,
          fileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData.token}`,
            },
          }
        );
      }

      toast.success("Blog updated successfully!");
      navigate("/dashboard/blogs");
    } catch (error) {
      console.error("Error updating blog:", error);
      toast.error("Failed to update blog.");
    } finally {
      setLoading(false);
    }
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  if (loading &&!blog) {
    return (
      <div className="container mt-5 d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  // if (!blog) return <div>Loading...</div>;

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div
            style={{ maxWidth: "99%" }}
            className="container-fluid col-12 mt-4 p-4 bg-light rounded shadow-sm"
          >
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h3 className="mb-4 fw-bold">Edit Blog</h3>
              <Link
                to="/dashboard/blogs"
                className="btn btn-secondary shadow-sm"
              >
                <i className="bi bi-arrow-left me-2"></i> Back to Blogs List
              </Link>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Slug</label>
                <input
                  type="text"
                  className="form-control"
                  name="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Heading</label>
                <input
                  type="text"
                  className="form-control"
                  name="heading"
                  value={formData.heading}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Description</label>
                <ReactQuill
                  theme="snow"
                  value={formData.description}
                  onChange={handleQuillChange}
                  modules={modules}
                  formats={formats}
                  placeholder="Enter blog description..."
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Blog Image</label>
                <input
                  type="file"
                  className="form-control"
                  name="blog_image"
                  onChange={handleChange}
                />
                {blog?.blog_image && (
                  <img
                    style={{ borderRadius: "10px" }}
                    src={blog.blog_image}
                    alt="About Us"
                    width="100"
                    className="mt-2"
                  />
                )}
              </div>
              <h5 className="mt-4 mb-3">SEO Metadata</h5>
              <div className="mb-3">
                <label className="form-label">SEO Title</label>
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={seoMetadata.title}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">SEO Description</label>
                <textarea
                  className="form-control"
                  name="description"
                  value={seoMetadata.description}
                  onChange={handleChange}
                />
              </div>
              {/* <div className="mb-3">
                <label className="form-label">Canonical URL</label>
                <input
                  type="text"
                  className="form-control"
                  name="canonical"
                  value={seoMetadata.canonical}
                  onChange={handleChange}
                />
              </div> */}
              <button type="submit" className="btn btn-primary w-25">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default EditBlog;
