import React, { useState } from "react";
import { Colors } from "../../globals/colors";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../../Services/UserContext";

import CustomModal from "../CustomModal";
import Login from "../../../Dashboard/Login";
import Register from "../../../Dashboard/Register";

import "../../css/ProductList.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const { addToCart, userData } = useUser();
  const toggleComponent = () => {
    setShowLogin(!showLogin);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const handleModalClose = () => setModalOpen(false);

  const sold = product.sold;
  const quantity = product.quantity;

  const additionalPercentage = (sold / quantity) * 100;
  const percentage = Math.min(
    additionalPercentage >= 50 ? additionalPercentage : additionalPercentage + 50,
    100
  );
  const setProductsPercentage = parseFloat(percentage.toFixed(1));

  let progressBarClass = "progress-green";
  let buttonStyle = {};
  let buttonText = "Add to Cart";

  if (percentage >= 70 && percentage < 90) {
    progressBarClass = "progress-orange";
  } else if (percentage >= 90 && percentage < 100) {
    progressBarClass = "progress-red";
  } else if (percentage === 100) {
    progressBarClass = "progress-red";
  }

  const addProductToCart = async (item) => {
    if (!userData?.token) {
      setModalOpen(true);
      return;
    }

    const payload = {
      product_id: item.id,
      quantity: 1,
      price: item.price * 1.16,
    };
    try {
      const response = await addToCart(payload);
      if (response.status === 200) {
        toast.success("Product added to cart");
        navigate("/cart");
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="col-md-12 tikker glow" style={{ backgroundColor: product?.draw_date !== null && "lightgray" }}>
      <ToastContainer />
      <div className="row">
        <div className="col-md-3">
          <div className="p-0 position-relative">
            <Link to={product?.draw_date === null &&`/product/${product.id}`} className="zoom d-block">
              <img
                className="card-img-bottom d-block"
                src={product.reward.image}
                style={{
                  filter: product?.draw_date !== null && "grayscale(80%)",
                  borderRadius: 20,
                }}
  
                alt="Card image cap"
              />
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className="products-card-body course-details pb-0"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "100%",
              alignItems: "flex-start",
            }}
          >
            <Link
              to={product?.draw_date === null &&`/product/${product.id}`}
              style={{ textDecoration: "none", color: Colors.themeColor }}
            >
              <h3 className="mb-sm-2 title">
                <span className="d-block-res "> {product.reward.name}</span>
              </h3>
            </Link>
            {product?.draw_date !== null && <h5 className="mb-3" style={{ textAlign: "left", color: "red" }}>
              Draw Data:
              {"  " + product?.draw_date}
            </h5>}
            <p className="mb-3" style={{ textAlign: "left" }}>
              {product.description}
            </p>

            {product?.draw_date === null && <div className="mt-md-1 mb-lg-0 mb-4 d-flex">
              <Link
                className={"prizeDetailsButton"}
                to={`/product/${product.id}`}
              >
                Prize Details
              </Link>
              <button
                className={"addCartButton"}
                style={buttonStyle}
                onClick={() => {
                  addProductToCart(product);
                }}
              >
                {buttonText}
              </button>
            </div>}
          </div>
        </div>
        <div className="col-md-3 col-12 hide-on-mobile prizes-values">
          <div className="value-status d-flex justify-content-between">
            {product?.draw_date === null && <div className="align-self-center">
              <h6 className="mb-0 card-heading">
                <p>
                  {setProductsPercentage}% Entries out of {"100%"}
                </p>
              </h6>
              <div className="progress">
                <div
                  className={progressBarClass}
                  role="progressbar"
                  style={{ width: `${percentage}%` }}
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>}
            <div className="d-flex justify-content-end align-items-center">
              <img
                src={require("../../images/share_ma.png")}
                style={{
                  cursor: "pointer",
                  width: 35,
                  height: 35.5,
                  justifyContent: "center",
                  alignSelf: "flex-end",
                }}
                className="img-fluid mb-3"
                alt=""
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/sharer/sharer.php?u=https://enaam.pk/",
                    "facebook-share-dialog",
                    "width=800,height=600"
                  )
                }
              />

              <img
                src={require("../../images/whatsapp_logo.png")}
                style={{
                  cursor: "pointer",
                  width: 36,
                  height: 36,
                  justifyContent: "center",
                  alignSelf: "flex-end",
                }}
                className="img-fluid mb-3 ml-3"
                alt=""
                onClick={() => {
                  const message = encodeURIComponent(
                    "Ready to turn your dreams into reality? Join Enaam.pk now for a chance to win amazing rewards! Sign up, play, and claim your prize. Don't miss out – your reward awaits!"
                  );
                  window.open(
                    `https://wa.me/?text=${message}`,
                    "whatsapp-share-dialog",
                    "width=800,height=600"
                  );
                }}
              />
            </div>
          </div>
          <CustomModal isOpen={isModalOpen} onClose={handleModalClose}>
            <div>
              {showLogin ? <Login /> : <Register />}
              <button
                onClick={toggleComponent}
                className="btn btn-link mt-2 "
                style={{
                  cursor: "pointer",
                  color: Colors.themeColor,
                  fontWeight: "bold",
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                {showLogin ? (
                  "Don't have an account?"
                ) : (
                  <>
                    <span style={{ color: "#343434", fontWeight: "400" }}>
                      Already have an account?
                    </span>{" "}
                    Go to Login
                  </>
                )}
              </button>
            </div>
          </CustomModal>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
