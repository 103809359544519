import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

function QuestionList() {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/questions`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });

        if (response.data.status === "Success") {
          setQuestions(response.data.data);
        } else {
          toast.error("Failed to fetch questions.");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error(
          `Error fetching questions: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  const handleFileUpload = async () => {
    if (!file) return;
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const binaryStr = event.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        const questionsFromFile = jsonData
          .map((row) => ({
            question_text: String(row[0] || "").trim(),
            option1: String(row[1] || "").trim(),
            option2: String(row[2] || "").trim(),
            option3: String(row[3] || "").trim(),
            option4: String(row[4] || "").trim(),
            option5: String(row[5] || "").trim(),
            option6: String(row[6] || "").trim(),
            right_option: String(row[5] || "").trim(),
          }))
          .filter(
            (q) =>
              q.question_text &&
              q.option1 &&
              q.option2 &&
              q.option3 &&
              q.option4 &&
              q.option5 &&
              q.option6 &&
              q.right_option
          );

        if (questionsFromFile.length === 0) {
          toast.error("No valid questions found in the uploaded file.");
          return;
        }

        try {
          const response = await axios.post(
            `${BASE_URL}/questions/upload`,
            { questions: questionsFromFile },
            {
              headers: {
                Authorization: `Bearer ${userData?.token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );

          console.log("Sending JSON data:", response?.data);

          if (response?.data?.message === "Questions uploaded successfully") {
            toast.success("File uploaded and data processed successfully!");
            // Optionally, re-fetch questions here to update the table
          } else {
            toast.error("Failed to upload the Excel file.");
          }
        } catch (error) {
          console.error(
            "Upload error:",
            error?.response?.data || error.message
          );
          toast.error(
            `Upload failed: ${error?.response?.data?.message || error.message}`
          );
        }
      };
      reader.readAsBinaryString(file);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleEditClick = (id) => {
    navigate(`/dashboard/questions/${id}/edit`);
  };

  const handleDeleteClick = async (id, closeModal) => {
    try {
      await axios.delete(`${BASE_URL}/questions/${id}`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      setQuestions(questions.filter((question) => question.id !== id));
      toast.success("Question deleted successfully!");
      closeModal(false);
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error(
        `Error deleting question: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const filteredQuestions = questions.filter(
    (question) =>
      question.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      question.options.some((option) =>
        option.optionTitle.toLowerCase().includes(searchTerm.toLowerCase())
      )
  );

  const tableData = filteredQuestions.map((question) => ({
    id: question.id,
    question: question.title,
    option1: question.options[0] ? question.options[0].optionTitle : "",
    option2: question.options[1] ? question.options[1].optionTitle : "",
    option3: question.options[2] ? question.options[2].optionTitle : "",
    option4: question.options[3] ? question.options[3].optionTitle : "",
    option5: question.options[4] ? question.options[4].optionTitle : "",
    option6: question.options[5] ? question.options[5].optionTitle : "",
    correctOption: question.options.find(
      (option) => option.correctOption === "Y"
    )
      ? question.options.find((option) => option.correctOption === "Y")
          .optionTitle
      : "",
  }));

  const headers = [
    { id: "question", label: "Question" },
    { id: "option1", label: "Option 1" },
    { id: "option2", label: "Option 2" },
    { id: "option3", label: "Option 3" },
    { id: "option4", label: "Option 4" },
    { id: "option5", label: "Option 5" },
    { id: "option6", label: "Option 6" },
    { id: "correctOption", label: "Correct Option" },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content mt-3">
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <Loader />
              </div>
            ) : (
              <DynamicTable
                title="Questions List"
                headers={headers}
                data={tableData}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
                onAdd={() => navigate("/dashboard/questions/add")}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                extraActions={
                  <div
                    className="input-group ms-3"
                    style={{ width: "300px" }}
                  >
                    <input
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    <button
                      className="btn btn-success"
                      onClick={handleFileUpload}
                    >
                      <i className="bi bi-upload me-2"></i>Upload
                    </button>
                  </div>
                }
              />
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default QuestionList;
