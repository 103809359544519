import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";

function BannerList() {
  const [banners, setBanners] = useState([]);
  const [platform, setPlatform] = useState("Both");
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/banners/platform/${platform}`,
          {
            headers: { Authorization: `Bearer ${userData?.token}` },
          }
        );

        if (response.data && Array.isArray(response.data.banners)) {
          setBanners(response.data.banners);
        } else {
          setBanners([]);
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching banners:", error);
        toast.error(
          `Error fetching banners: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBanners();
  }, [platform, userData]);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={setIsSidebarOpen} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content">
        

            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "70vh" }}
              >
                <Loader />
              </div>
            ) : (
              <DynamicTable
                title="Banners List"
                headers={[
                  { id: "description", label: "Description" },
                  { id: "product_link", label: "Product Link" },
                  { id: "platform", label: "Platform" },
                  { id: "image", label: "Image", type: "image" },
                ]}
                data={banners}
                onEdit={(id) => navigate(`/dashboard/banners/${id}/edit`)}
                onDelete={async (id, closeModal) => {
                  await axios.delete(`${BASE_URL}/banners/${id}`, {
                    headers: { Authorization: `Bearer ${userData?.token}` },
                  });
                  setBanners(banners.filter((banner) => banner.id !== id));
                  toast.success("Banner deleted successfully!");
                  closeModal(false);
                }}
                onAdd={() => navigate("/dashboard/banners/add")}
                showViewButton={false}
              />
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default BannerList;
