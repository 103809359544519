import React from "react";

import { Helmet } from "react-helmet";
import { useUser } from "../../../Services/UserContext";
import { WinnerComponent } from "./Components/WinnerComponent";

import Header from "../../Components/HeaderComponent";
import Footer from "../../Components/FooterCompnent";
import Loader from "../../Components/LoaderComponent";
import "../../css/Styles.css";

const Winners = () => {
  const { winners, loading } = useUser();

  return (
    <div className="App">
      <Helmet>
        <title>Meet Enaam's Winners - Celebrate Success Stories</title>
        <meta
          name="description"
          content="Explore Enaam's winners who triumphed in skill-based competitions, earning exciting prizes like gadgets, appliances, and cars. See their success stories today!"
        />
        <link rel="canonical" href="https://enaam.pk/winners" />
      </Helmet>
      <Header />
      <section className="home-header inner-page">
        <div className="container-fluid p-0">
          <h3 className="mb-2 text-center">Winners</h3>
          <p className="mb-2 text-center">
            All our winners are announced in this section.
          </p>
          <div
            className="w3l-bottom-grids-6 service-w3l-bg py-2 winners-list"
            id="winners"
          >
            <div className="container-fluid winners-cards">
              <div className="row pt-md-4 justify-content-center">
                <div className="row col-12 justify-content-center gap-0 p-md-2 p-0 p-lg-2">
                  {loading ? (
                    <Loader />
                  ) : winners.length === 0 ? (
                    <p className="text-center d-block">No winners found</p>
                  ) : (
                    [...winners]
                      .sort(
                        (a, b) => new Date(b.draw_date) - new Date(a.draw_date)
                      )
                      .map((winner, index) => {
                        const announcementDate = new Date(winner?.draw_date);
                        const formattedDate = announcementDate.toLocaleString(
                          "en-US",
                          {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }
                        );

                        return (
                          <WinnerComponent
                            winner={winner}
                            data={formattedDate}
                            index={index}
                          />
                        );
                      })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Winners;
