import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { DashboardRoutes } from "./Dashboard/index.js";
import UserSide from "./UserSide/index.js";
import { UserProvider } from "./Services/UserContext.js";
import { AuthProvider } from "./Services/AuthContext.js";
import Home from "./UserSide/pages/HomeScreen/index";
import YouTubeChannelVideos from "./UserSide/pages/HomeScreen/Components/YouTubeChannelVideos";
import Prizes from "./UserSide/pages/PrizesScreen/index.js";
import Winners from "./UserSide/pages/WinnersPage/index.js";
import AboutUs from "./UserSide/pages/AboutUsScreen/index.js";
import RecentLuckyDraws from "./UserSide/pages/RecentLuckyDrawsScreen/index.js";
import Blogs from "./UserSide/pages/BlogsScreen/index.js";
import Contact from "./UserSide/pages/ContactsUsScreen/Components/Contact.js";
import TermsConditions from "./UserSide/pages/Terms&ConditionsScreen/index.js";
import FAQs from "./UserSide/pages/FAQsScreen/index.js";
import CartScreen from "./UserSide/pages/CartScreen/index.js";
import ProductDetails from "./UserSide/pages/PrizesScreen/ProductDetails.js";
import InititalGameScreen from "./UserSide/pages/GameScreen/index.js";
import ProfileScreen from "./Dashboard/ProfileScreen.js";
import PrivacyPolicy from "./UserSide/pages/PrivacyPolicy/index.js";
import GameScreen from "./UserSide/pages/GameScreen/GameScreen.js";
// import AdminDashboard from './Dashboard/AdminDashboard.js';
import PrivateRoute from "./Dashboard/PrivateRoute.js";
import UserStats from "./Dashboard/StatsPotralScreen/UserStats.js";
import ParticipantsList from "./Dashboard/Participants/ParticipantsList.js";
import Error from "./404ErrorScreen/Error.js";
import ParticipantDetails from "./Dashboard/Participants/ParticipantDetails.js";
import BrandAmbassadors from "./Dashboard/BrandAmbassadorScreen/Amabassadors.js";
import AmbassadorDetailsPage from "./Dashboard/BrandAmbassadorScreen/AmbassadorsDetailPage.js";
import InvoicePage from "./Dashboard/invoice/InvoicePage.js";
import InvoiceDetailsPage from "./Dashboard/invoice/InvoiceDetailsPage.js";
import RewardsList from "./Dashboard/rewards/RewardsList.js";
import RewardCreate from "./Dashboard/rewards/RewardCreate";
import RewardEdit from "./Dashboard/rewards/RewardEdit.js";
import WinnerList from "./Dashboard/winners/WinnersList.js";
import WinnerCreate from "./Dashboard/winners/WinnerCreate.js";
import WinnerEdit from "./Dashboard/winners/WinnerEdit.js";
import WinnerDetails from "./Dashboard/winners/WinnerDetails.js";
import HangWinnersList from "./Dashboard/WinnersHang/HangWinnersList.js";
import CreateHangWinner from "./Dashboard/WinnersHang/CreateHangWinner.js";
import EditHangWinner from "./Dashboard/WinnersHang/EditHangWinner.js";
import ProductsList from "./Dashboard/products/ProductsList.js";
import NewProduct from "./Dashboard/products/NewProduct.js";
import EditProduct from "./Dashboard/products/EditProduct.js";
import BannerList from "./Dashboard/banners/BannerList.js";
import AddBanner from "./Dashboard/banners/AddBanner.js";
import EditBanner from "./Dashboard/banners/EditBanner.js";
import ViewBanner from "./Dashboard/banners/ViewBanner.js";
import BannerDisplay from "./Dashboard/banners/BannerDisplay.js";
import VideoList from "./Dashboard/videos/VideoList.js";
import AddVideo from "./Dashboard/videos/AddVideo.js";
import EditVideo from "./Dashboard/videos/EditVideo.js";
import ViewVideo from "./Dashboard/videos/ViewVideo.js";
import AddBlog from "./Dashboard/blogs/AddBlog.js";
import EditBlog from "./Dashboard/blogs/EditBlog.js";
import ViewBlog from "./Dashboard/blogs/ViewBlog.js";
import BlogList from "./Dashboard/blogs/BlogList.js";
import FAQList from "./Dashboard/faqs/FAQList.js";
import CreateFAQ from "./Dashboard/faqs/CreateFAQ.js";
import EditFAQ from "./Dashboard/faqs/EditFAQ.js";
import QuestionList from "./Dashboard/questions/QuestionList.js";
import AddQuestion from "./Dashboard/questions/AddQuestion.js";
import AnswerQuestion from "./Dashboard/questions/AnswerQuestion.js";
import EditQuestion from "./Dashboard/questions/EditQuestion.js";
import AboutUsList from "./Dashboard/aboutus/AboutUsList.js";
import CreateAboutUs from "./Dashboard/aboutus/CreateAboutUs.js";
import EditAboutUs from "./Dashboard/aboutus/EditAboutUs.js";
import ContactList from "./Dashboard/Contacts/ContactList.js";
import UserEdit from "./Dashboard/UserEdit.js";
import ResetPassword from "./Dashboard/ResesPassword.js";
import ForgotPassword from "./Dashboard/ForgotPassword.js";
import BlogDetails from "./UserSide/pages/BlogsScreen/BlogDetails.js";
import ProductDetail from "./Dashboard/products/ProductDetail.js";
import OfferList from "./Dashboard/OffersScreen/OffersList.js";
import AddOffers from "./Dashboard/OffersScreen/AddOffers.js";
import EditOffer from "./Dashboard/OffersScreen/EditOffer.js";
import MonthlyStatistics from "./Dashboard/StatsPotralScreen/MonthlyStatsScreen.js";
import InActiveUsers from "./Dashboard/UnActiveUsersScreen/unActiveUsers.js";
const AdminDashboard = lazy(() => import("./Dashboard/AdminDashboard.js"));

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthProvider>
        <UserProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/youtube-videos"
                element={<YouTubeChannelVideos />}
              />
              <Route path="/prizes" element={<Prizes />} />
              <Route path="/winners" element={<Winners />} />
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/recentLuckyDraws" element={<RecentLuckyDraws />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/contactUs" element={<Contact />} />
              <Route path="/terms&Conditions" element={<TermsConditions />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/cart" element={<CartScreen />} />
              <Route path="/product/:id" element={<ProductDetails />} />
              <Route path="/blogs/:slug" element={<BlogDetails />} />
              <Route
                path="/initialGameScreen"
                element={<InititalGameScreen />}
              />
              <Route path="/profileScreen" element={<ProfileScreen />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/GameScreen" element={<GameScreen />} />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute
                    element={AdminDashboard}
                    allowedRoles={["admin", "editor", "viewer"]}
                  />
                }
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/dashboard/forgot-password"
                element={<ForgotPassword />}
              />
              <Route
                path="/dashboard/users/:id"
                element={<PrivateRoute element={UserEdit} />}
              />
              <Route path="/dashboard/inactive-users" element={<PrivateRoute element={InActiveUsers} />} /> 
              <Route path="/dashboard/stats" element={<PrivateRoute element={UserStats} />} />
              <Route
                path="/dashboard/participants"
                element={<PrivateRoute element={ParticipantsList} />} 
              />
              <Route
                path="/dashboard/participants/:id"
                element={<PrivateRoute element={ParticipantDetails} />}
              />
              <Route
                path="/dashboard/ambassadors"
                element={<PrivateRoute element={BrandAmbassadors} />}
              />
              <Route
                path="/dashboard/ambassadors/:id"
                element={<PrivateRoute element={AmbassadorDetailsPage} />}
              />
              <Route path="/dashboard/invoices" element={<PrivateRoute element={InvoicePage} />}
              />
              <Route
                path="/dashboard/invoices/:id"
                element={<PrivateRoute element={InvoiceDetailsPage} />}
              />
              <Route path="/dashboard/offers" element={<PrivateRoute element={OfferList} />} />
              <Route path="/dashboard/offers/add" element={<PrivateRoute element={AddOffers} />} />
              <Route
                path="/dashboard/offers/:id/edit"
               element={<PrivateRoute element={EditOffer} />}
              />
              <Route
                path="/dashboard/rewards"
                element={<PrivateRoute element={RewardsList} />}
              />
              <Route
                path="/dashboard/rewards/create"
                element={<PrivateRoute element={RewardCreate} />}
              />
              <Route
                path="/dashboard/rewards/:id/edit"
                element={<PrivateRoute element={RewardEdit} />}
              />
              <Route path="/dashboard/winners" element={<PrivateRoute element={WinnerList} />}  />
              <Route path="/winners/create" element={<PrivateRoute element={WinnerCreate} />} />
              <Route path="/winners/:id/edit" element={<PrivateRoute element={WinnerEdit} />} />
              <Route path="/winners/:id" element={<PrivateRoute element={WinnerDetails} />} />
              <Route
                path="/dashboard/hang-winners"
                element={<PrivateRoute element={HangWinnersList}/>}
              />
              <Route
                path="/hang-tower-winners/create"
                element={<PrivateRoute element={CreateHangWinner}/>}
              />
              <Route
                path="/hang-tower-winners/:id/edit"
                element={<PrivateRoute element={EditHangWinner} />}
              />
              <Route
                path="/dashboard/products"
                element={<PrivateRoute element={ProductsList} />}
              />
              <Route
                path="/dashboard/products/create"
                element={<PrivateRoute element={NewProduct} />}
              />
              <Route
                path="/dashboard/products/:id/edit"
                element={<PrivateRoute element={EditProduct} />}
              />
              <Route
                path="/dashboard/products/:id"
                element={<PrivateRoute element={ProductDetail} />}
              />
              <Route
                path="/dashboard/banners"
                element={<PrivateRoute element={BannerList} />}
              />
              <Route
                path="/dashboard/banners/add"
                element={<PrivateRoute element={AddBanner} />}
              />
              <Route
                path="/dashboard/banners/:id/edit"
                element={<PrivateRoute element={EditBanner} />}
              />
              <Route
                path="/dashboard/banners/:id"
                element={<PrivateRoute element={ViewBanner} />}
              />
              <Route
                path="/dashboard/banners/desktop"
                element={
                  <PrivateRoute
                    element={<BannerDisplay platform="desktop" />}
                  />
                }
              />
              <Route
                path="/dashboard/banners/mobile"
                element={
                  <PrivateRoute element={<BannerDisplay platform="mobile" />} />
                }
              />
              <Route
                path="/dashboard/videos"
                element={<PrivateRoute element={VideoList} />}
              />
              <Route
                path="/dashboard/videos/add"
                element={<PrivateRoute element={AddVideo} />}
              />
              <Route
                path="/dashboard/videos/:id/edit"
                element={<PrivateRoute element={EditVideo} />}
              />
              <Route
                path="/dashboard/videos/:id"
                element={<PrivateRoute element={ViewVideo} />}
              />
              <Route
                path="/dashboard/blogs/add"
                element={<PrivateRoute element={AddBlog} />}
              />
              <Route
                path="/dashboard/blogs/:slug/edit"
                element={<PrivateRoute element={EditBlog} />}
              />
              <Route
                path="/dashboard/blogs/:id"
                element={<PrivateRoute element={ViewBlog} />}
              />
              <Route
                path="/dashboard/blogs"
                element={<PrivateRoute element={BlogList} />}
              />
              <Route
                path="/dashboard/faqs"
                element={<PrivateRoute element={FAQList} />}
              />
              <Route
                path="/dashboard/faqs/create"
                element={<PrivateRoute element={CreateFAQ} />}
              />
              <Route
                path="/dashboard/faqs/:id/edit"
                element={<PrivateRoute element={EditFAQ} />}
              />
              <Route
                path="/dashboard/questions"
                element={<PrivateRoute element={QuestionList} />}
              />
              <Route
                path="/dashboard/questions/add"
                element={<PrivateRoute element={AddQuestion} />}
              />
              <Route
                path="/dashboard/questions/:id/answer"
                element={<PrivateRoute element={AnswerQuestion} />}
              />
              <Route
                path="/dashboard/questions/:id/edit"
                element={<PrivateRoute element={EditQuestion} />}
              />
              <Route
                path="/dashboard/about-us"
                element={<PrivateRoute element={AboutUsList} />}
              />
              <Route
                path="/dashboard/about-us/create"
                element={<PrivateRoute element={CreateAboutUs} />}
              />
              <Route
                path="/dashboard/about-us/:id/edit"
                element={<PrivateRoute element={EditAboutUs} />}
              />
              <Route
                path="/dashboard/contacts"
                element={<PrivateRoute element={ContactList} />}
              />
              <Route
                path="/dashboard/monthly-stats"
                element={<PrivateRoute element={MonthlyStatistics}/>}
              />
              <Route path="*" element={<Error />} />
            </Routes>
          </Router>
        </UserProvider>
      </AuthProvider>
    </Suspense>
  );
}

export default App;
