import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import 'react-toastify/dist/ReactToastify.css';

import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import DynamicTable from '../Components/TableComponent';

function AboutUsList() {
    const [aboutUs, setAboutUs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const navigate = useNavigate();
    const { userData } = useUser();

    useEffect(() => {
        const fetchAboutUs = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/about-us`, {
                    headers: { Authorization: `Bearer ${userData?.token}` },
                });

                if (response.data.status === 200 && Array.isArray(response.data.aboutUs)) {
                    setAboutUs(response.data.aboutUs);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching About Us data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchAboutUs();
    }, []);

    const handleEditClick = (id) => {
        navigate(`/dashboard/about-us/${id}/edit`);
    };

    const handleDeleteClick = async (id, closeModal) => {
        try {
            await axios.delete(`${BASE_URL}/about-us/${id}`, {
                headers: { Authorization: `Bearer ${userData?.token}` },
            });
            setAboutUs(aboutUs.filter(item => item.id !== id));
            toast.success('About Us item deleted successfully!');
            closeModal(false);
        } catch (error) {
            console.error('Error deleting About Us data:', error);
            toast.error(error.response?.data?.message || error.message);
        }
    };

    const handleAddClick = () => {
        navigate("/dashboard/about-us/create");
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    {loading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                            <Loader />
                        </div>
                    ) : (
                        <DynamicTable
                            title="About Us List"
                            headers={[
                                { id: "heading", label: "Heading" },
                                { id: "about_detail", label: "Detail" },
                                { id: "about_image", label: "Image", type: "image" }, 
                            ]}
                            data={aboutUs}
                            onEdit={handleEditClick}
                            onDelete={handleDeleteClick}
                            onAdd={handleAddClick}
                        />
                    )}
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default AboutUsList;
