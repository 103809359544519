import React, { useState, useEffect } from "react";
import Loader from "../../UserSide/Components/LoaderComponent";
import Sidebar from "../sidebar/Sidebar";

import dayjs from "dayjs";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import {
  People,
  Paid,
  AttachMoneyOutlined,
  LaptopChromebookOutlined,
  TvOutlined,
  ProductionQuantityLimits,
  BikeScooterOutlined,
  WashOutlined,
  AirOutlined,
  VolunteerActivismSharp,
  refrigeratorCount,
  CardGiftcard,
  VolumeUp,
} from "@mui/icons-material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DateRangePickerComponent from "./Components/DatePicker";
import { Link } from "react-router-dom";
import useMonthlyStats from "./Components/useMonthlyStats";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
const UserStats = () => {
  const {
    currentMonthData,
    previousMonthData,
    users,
    participants,
    calculatePaidParticipants,
    calculateTotalSales,
    products,
    loading,
  } = useMonthlyStats();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [filteredTotalUsers, setFilteredTotalUsers] = useState(0);
  const [paidParticipants, setPaidParticipants] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [filteredTotalAmount, setFilteredTotalAmount] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredTotalQuantity, setFilteredTotalQuantity] = useState(0);
  const [bikecount, setBikeCount] = useState(0);
  const [iphonecount, setIphoneCount] = useState(0);
  const [airpodscount, setairpodsCount] = useState(0);
  const [refrigeratorcount, setRefrigeratorCount] = useState(0);
  const [laptopcount, setLaptopCount] = useState(0);
  const [lcdCount, setLcdCount] = useState(0);
  const [coolerCount, setCoolerCount] = useState(0);
  const [cycleCount, setCycleCount] = useState(0);
  const [machineCount, setMachineCount] = useState(0);
  const [conditionerCount, setConditionerCount] = useState(0);
  const [voucherCount, setVoucherCount] = useState(0);
  const [xBoxCount, setXboxCount] = useState(0);
  const [phoneCount, setPhoneCount] = useState(0);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
  useEffect(() => {
    if (participants.length > 0) {
      setPaidParticipants(calculatePaidParticipants(participants));
      setTotalSales(calculateTotalSales(participants));
    }
  }, [participants, calculatePaidParticipants, calculateTotalSales]);

  useEffect(() => {
    if (products.length > 0 && products[0].status === 200) {
      products[0].icon = TwoWheelerIcon;
    }
  }, [products]);

  const filterStatsByDate = () => {
    const [startDate, endDate] = calculateDateRange(dateRange);
    const filteredParticipants = participants.filter((participant) => {
      const createdAt = new Date(participant.created_at);
      return createdAt >= startDate && createdAt <= endDate;
    });
    const filteredInvoices = filteredParticipants
      .map((participant) => participant.processed_invoice)
      .filter((invoice) => {
        const invoiceCreatedAt = new Date(invoice.created_at);
        return invoiceCreatedAt >= startDate && invoiceCreatedAt <= endDate;
      });
    const filteredTotalQuantity = filteredInvoices.reduce((sum, invoice) => {
      return sum + invoice.total_quantity;
    }, 0);

    setFilteredTotalAmount(calculateTotalSales(filteredParticipants));
    setFilteredTotalQuantity(filteredTotalQuantity);

    const {
      iphoneCount,
      bikeCount,
      airpodsCount,
      refrigeratorCount,
      laptopCount,
      lcdCount,
      coolerCount,
      cycleCount,
      machineCount,
      conditionerCount,
      voucherCount,
      xBoxCount,
      phoneCount,
    } = countProducts(filteredParticipants);
    setBikeCount(bikeCount);
    setIphoneCount(iphoneCount);
    setairpodsCount(airpodsCount);
    setRefrigeratorCount(refrigeratorCount);
    setLaptopCount(laptopCount);
    setLcdCount(lcdCount);
    setCoolerCount(coolerCount);
    setCycleCount(cycleCount);
    setMachineCount(machineCount);
    setConditionerCount(conditionerCount);
    setVoucherCount(voucherCount);
    setXboxCount(xBoxCount);
    setPhoneCount(phoneCount);

    const filteredUsers = users.filter((user) => {
      const createdAt = new Date(user.created_at);
      return createdAt >= startDate && createdAt <= endDate;
    });

    setFilteredTotalUsers(filteredUsers.length);
  };

  const calculateDateRange = ([start, end]) => {
    const startDate = start
      ? new Date(start)
      : dayjs().subtract(1, "day").toDate();
    const endDate = end ? new Date(end) : dayjs().endOf("day").toDate();
    const formattedStartDate = new Date(
      `${startDate.toISOString().split("T")[0]}T00:00:00Z`
    );
    const formattedEndDate = new Date(
      `${endDate.toISOString().split("T")[0]}T23:59:59Z`
    );

    return [formattedStartDate, formattedEndDate];
  };
  const countProducts = (participants) => {
    let iphoneCount = 0;
    let bikeCount = 0;
    let airpodsCount = 0;
    let refrigeratorCount = 0;
    let laptopCount = 0;
    let lcdCount = 0;
    let coolerCount = 0;
    let cycleCount = 0;
    let machineCount = 0;
    let conditionerCount = 0;
    let voucherCount = 0;
    let xBoxCount = 0;
    let phoneCount = 0;

    participants.forEach((participant) => {
      if (
        participant.processed_invoice &&
        participant.processed_invoice.products
      ) {
        let products = participant.processed_invoice.products;

        if (!Array.isArray(products)) {
          products = Object.values(products);
        }
        products.forEach((product) => {
          const productName = product.name?.toLowerCase();
          if (productName === "ev-bike") bikeCount++;
          if (productName === "digital watch") iphoneCount++;
          if (productName === "air pods") airpodsCount++;
          if (productName === "refrigerator") refrigeratorCount++;
          if (productName === "laptop") laptopCount++;
          if (productName === "lcd tv") lcdCount++;
          if (productName === "room cooler") coolerCount++;
          if (productName === "sports cycle") cycleCount++;
          if (productName === "washing machine") machineCount++;
          if (productName === "air conditioner") conditionerCount++;
          if (productName === "gift hamper") voucherCount++;
          if (productName === "xbox 360") xBoxCount++;
          if (productName === "smart phone") phoneCount++;
        });
      }
    });

    return {
      iphoneCount,
      bikeCount,
      airpodsCount,
      refrigeratorCount,
      laptopCount,
      lcdCount,
      coolerCount,
      cycleCount,
      machineCount,
      conditionerCount,
      voucherCount,
      xBoxCount,
      phoneCount,
    };
  };

  useEffect(() => {
    filterStatsByDate();
  }, [dateRange, participants]);

  const downloadStatsPDF = () => {
    if (filteredTotalUsers === 0 && filteredTotalQuantity === 0) {
      alert("No data available for the selected date range.");
      return;
    }
    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.text("Stats Report", 14, 20);
    doc.setFontSize(12);
    const [startDate, endDate] = calculateDateRange(dateRange);
    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : "N/A";
    const formattedEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : "N/A";

    doc.text(`Date Range: ${formattedStartDate} - ${formattedEndDate}`, 14, 30);

    const tableData = [
      ["Total Sales (PKR)", filteredTotalAmount.toFixed(2)],
      ["Total Participants", participants.length],
      ["Total Users", filteredTotalUsers],
      ["Total Tickets Sold", filteredTotalQuantity],
      ["iPhone Sales", iphonecount],
      ["Bike Sales", bikecount],
      ["AirPods Sales", airpodscount],
      ["Refrigerator Sales", refrigeratorcount],
      ["Laptop Sales", laptopcount],
      ["LCD Sales", lcdCount],
      ["Cooler Sales", coolerCount],
      ["Cycle Sales", cycleCount],
      ["Washing Machine Sales", machineCount],
      ["Air Conditioner Sales", conditionerCount],
      ["Voucher Sales", voucherCount],
      ["Xbox Sales", xBoxCount],
      ["Smart Phone Sales", phoneCount],
    ];

    doc.autoTable({
      startY: 40,
      head: [["Stats", "Count"]],
      body: tableData,
      theme: "grid",
      styles: { fontSize: 10, cellPadding: 3 },
      headStyles: { fillColor: [0, 112, 192] },
    });

    doc.save(`Stats_Report_${formattedStartDate} - ${formattedEndDate}.pdf`);
  };

  const downloadStatsExcel = () => {
    if (filteredTotalUsers === 0 && filteredTotalQuantity === 0) {
      alert("No data available for the selected date range.");
      return;
    }
    const [startDate, endDate] = calculateDateRange(dateRange);
    const formattedStartDate = startDate
      ? startDate.toISOString().split("T")[0]
      : "N/A";
    const formattedEndDate = endDate
      ? endDate.toISOString().split("T")[0]
      : "N/A";
    const excelData = [
      {
        Category: "Date Range",
        Amount: `${formattedStartDate} - ${formattedEndDate}`,
      },
      { Category: "Total Sales (PKR)", Amount: filteredTotalAmount.toFixed(2) },
      { Category: "Total Participants", Stats: participants.length },
      { Category: "Total Users", Stats: filteredTotalUsers },
      { Category: "Total Tickets Sold", Stats: filteredTotalQuantity },
      { Category: "iPhone Sales", Stats: iphonecount },
      { Category: "Bike Sales", Stats: bikecount },
      { Category: "AirPods Sales", Stats: airpodscount },
      { Category: "Refrigerator Sales", Stats: refrigeratorcount },
      { Category: "Laptop Sales", Stats: laptopcount },
      { Category: "LCD Sales", Stats: lcdCount },
      { Category: "Cooler Sales", Stats: coolerCount },
      { Category: "Cycle Sales", Stats: cycleCount },
      { Category: "Washing Machine Sales", Stats: machineCount },
      { Category: "Air Conditioner Sales", Stats: conditionerCount },
      { Category: "Voucher Sales", Stats: voucherCount },
      { Category: "Xbox Sales", Stats: xBoxCount },
      { Category: "Smart Phone Sales", Stats: phoneCount },
    ];
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    worksheet["!cols"] = [{ wch: 30 }, { wch: 15 }];

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stats Report");

    XLSX.writeFile(
      workbook,
      `Stats_Report_${formattedStartDate} - ${formattedEndDate}.xlsx`
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-light rounded text-left">
              Current Stats
            </h1>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center dashboard-loader"
                style={{ height: "60vh" }}
              >
                <Loader />
              </div>
            ) : (
              <>
                {currentMonthData && (
                  <Box sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                                <People />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Users
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {currentMonthData?.total_users}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#f57c00", mr: 2 }}>
                                <Paid />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Participants
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {currentMonthData?.total_participants}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/invoices"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Tickets
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {currentMonthData?.total_quantity}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Amount
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  PKR. {currentMonthData?.total_amount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 mt-4 bg-light rounded text-left">
                  Previous Month
                </h1>
                {previousMonthData && (
                  <Box sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                                <People />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Users
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {previousMonthData?.total_users}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#f57c00", mr: 2 }}>
                                <Paid />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Participants
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {previousMonthData?.total_participants}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/invoices"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Tickets
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {previousMonthData?.total_quantity}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Amount
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  PKR. {previousMonthData?.total_amount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 mt-4 bg-light rounded text-left">
                  Total Stats
                </h1>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={"/dashboard"}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: 6,
                            },
                          }}
                        >
                          <CardContent
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ bgcolor: "#3f51b5", mr: 2, mb: 6 }}>
                              <People />
                            </Avatar>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{ display: "block", lineHeight: 2 }}
                              >
                                Total Users
                              </Typography>
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 0.5 }}
                              >
                                {users.length}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={"/dashboard/participants"}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: 6,
                            },
                          }}
                        >
                          <CardContent
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ bgcolor: "#f57c00", mr: 2, mb: 6 }}>
                              <Paid />
                            </Avatar>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{ display: "block", lineHeight: 2 }}
                              >
                                Paid Participants
                              </Typography>
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 0.5 }}
                              >
                                {paidParticipants}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={"/dashboard/participants"}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: 6,
                            },
                          }}
                        >
                          <CardContent
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ bgcolor: "#009688", mr: 2, mb: 6 }}>
                              <AttachMoneyOutlined />
                            </Avatar>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{ display: "block", lineHeight: 2 }}
                              >
                                Total Sales
                              </Typography>
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 0.5 }}
                              >
                                PKR. {totalSales.toFixed(2)}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={3}>
                    {products.map((product) => (
                      <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {product.id == "38" ? (
                                <Avatar
                                  sx={{ bgcolor: "#FF5722", mr: 2, mb: 6 }}
                                >
                                  <TwoWheelerIcon />
                                </Avatar>
                              ) : product.id == "39" ? (
                                <Avatar
                                  sx={{ bgcolor: "#4CAF50", mr: 2, mb: 6 }}
                                >
                                  <PhoneAndroidIcon />
                                </Avatar>
                              ) : product.id == "49" ? (
                                <Avatar
                                  sx={{ bgcolor: "#3f51b5", mr: 2, mb: 6 }}
                                >
                                  <LaptopChromebookOutlined />
                                </Avatar>
                              ) : product.id == "50" ? (
                                <Avatar
                                  sx={{ bgcolor: "#2196F3", mr: 2, mb: 6 }}
                                >
                                  <TvOutlined />
                                </Avatar>
                              ) : product.id == "51" ? (
                                <Avatar
                                  sx={{ bgcolor: "#2196F3", mr: 2, mb: 6 }}
                                >
                                  <ProductionQuantityLimits />
                                </Avatar>
                              ) : product.id == "52" ? (
                                <Avatar
                                  sx={{ bgcolor: "#c1272d", mr: 2, mb: 6 }}
                                >
                                  <BikeScooterOutlined />
                                </Avatar>
                              ) : product.id == "53" ? (
                                <Avatar
                                  sx={{ bgcolor: "#ffbf69", mr: 2, mb: 6 }}
                                >
                                  <WashOutlined />
                                </Avatar>
                              ) : product.id == "54" ? (
                                <Avatar
                                  sx={{ bgcolor: "#1b1464", mr: 2, mb: 6 }}
                                >
                                  <AirOutlined />
                                </Avatar>
                              ) : product.id == "55" ? (
                                <Avatar
                                  sx={{ bgcolor: "#c2276c", mr: 2, mb: 6 }}
                                >
                                  <VolunteerActivismSharp />
                                </Avatar>
                              ) : product.id == "56" ? (
                                <Avatar
                                  sx={{ bgcolor: "#69c3ff", mr: 2, mb: 6 }}
                                >
                                  <CardGiftcard />
                                </Avatar>
                              ) : (
                                <Avatar
                                  sx={{ bgcolor: "#FFC107", mr: 2, mb: 6 }}
                                >
                                  <ReceiptIcon />
                                </Avatar>
                              )}

                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    display: "block",
                                    lineHeight: 2,
                                  }}
                                >
                                  {product.name}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  Sold : {product.sold || 0}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ mt: 6 }}>
                    <div className="fs-3 fw-bold text-dark shadow-sm p-3 mb-4 bg-light rounded">
                      <div className="row align-items-center">
                        <div className="col col-sm-2 col-lg-4 d-flex justify-content-start justify-content-lg-start align-items-center">
                          <h2 className="fs-3 fw-bold text-dark">Difference</h2>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-end justify-content-lg-end mt-2 mt-lg-0">
                          <DateRangePickerComponent
                            onDateChange={setDateRange}
                          />
                        </div>
                        <div className="col col-lg-2 d-flex justify-content-center justify-content-lg-end mt-2 mt-lg-0 gap-2">
                          <button
                            className="btn btn-primary"
                            onClick={downloadStatsPDF}
                          >
                            <i className="bi bi-download"></i> PDF
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={downloadStatsExcel}
                          >
                            <i className="bi bi-download"></i> Excel
                          </button>
                        </div>
                      </div>
                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2, mb: 6 }}>
                                <People />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Users:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {filteredTotalUsers}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/participants`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2, mb: 6 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Sales:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  PKR. {filteredTotalAmount.toFixed(2)}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/invoices`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2, mb: 6 }}>
                                <ReceiptIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Tickets:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {filteredTotalQuantity}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#FF5722", mr: 2, mb: 6 }}>
                                <TwoWheelerIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Bike Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {bikecount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#4CAF50", mr: 2, mb: 6 }}>
                                <PhoneAndroidIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Digital Watch:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {iphonecount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#FFC107", mr: 2, mb: 6 }}>
                                <ReceiptIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Airpods Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {airpodscount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#FFC107", mr: 2, mb: 6 }}>
                                <ReceiptIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Refrigerator Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {refrigeratorcount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#1b1464", mr: 2, mb: 6 }}>
                                <AirOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Air Conditioner Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {conditionerCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2, mb: 6 }}>
                                <LaptopChromebookOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Laptop Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {laptopcount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#2196F3", mr: 2, mb: 6 }}>
                                <TvOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  LCD Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {lcdCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#FFC107", mr: 2, mb: 6 }}>
                                <PhoneAndroidIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Smart Phone Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {phoneCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#2196F3", mr: 2, mb: 6 }}>
                                <ProductionQuantityLimits />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Room Cooler Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {coolerCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#c1272d", mr: 2, mb: 6 }}>
                                <BikeScooterOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Sports cycle Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {cycleCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#ffbf69", mr: 2, mb: 6 }}>
                                <WashOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Washing Machine Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {machineCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#c2276c", mr: 2, mb: 6 }}>
                                <VolunteerActivismSharp />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Amanah Voucher Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {voucherCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#69c3ff", mr: 2, mb: 6 }}>
                                <CardGiftcard />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  XBox Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {xBoxCount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserStats;
