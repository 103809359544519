
'use client'

import React, { useEffect, useRef } from 'react';
import './Styless.css';

export default function GoogleTranslate() {
  const scriptLoadedRef = useRef(false)

  useEffect(() => {
    if (!scriptLoadedRef.current) {
      const addScript = document.createElement('script')
      addScript.setAttribute(
        'src',
        '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
      )
      document.body.appendChild(addScript)
      scriptLoadedRef.current = true
    }

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: '',
          includedLanguages: 'en,ur',
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: true, // Set this to true to show both languages
        },
        'google_translate_element'
      )
    }

    return () => {
      const script = document.querySelector(
        'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
      )
      if (script) {
        document.body.removeChild(script)
      }
      delete window.googleTranslateElementInit
    }
  }, [])

  return <div id="google_translate_element" className="mr-4 fw-bold" />
}
