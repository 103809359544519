import React, { createContext, useContext, useState, useEffect } from "react";
import { BASE_URL } from ".";
import axios from "axios";
import { toast } from "react-toastify";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const [products, setProducts] = useState([]);
  const [winners, setWinners] = useState([]);
  const [videos, setRecentVideos] = useState([]);
  const [cartQuantity, setCartQuantity] = useState("");
  const [participants, setParticipants] = useState([]);
  const [participantsLoading, setParticipantsLoading] = useState(true);
  const [totalAmount, setTotalAmount] = useState(0);
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const storedUserData = localStorage.getItem("userData");
        if (storedUserData) {
          setUser(JSON.parse(storedUserData));
        }
      } catch (error) {
        console.error("Failed to load user data:", error);
      }
    };

    loadUserData();
    getBanners();
    getVideos();
    getWinners();
    getCartQuantity();
    getCart();
  }, []);

  useEffect(() => {
    const saveUserData = async () => {
      try {
        if (userData) {
          localStorage.setItem("userData", JSON.stringify(userData));
        } else {
          localStorage.removeItem("userData");
        }
      } catch (error) {
        console.error("Failed to save user data:", error);
      }
    };
    saveUserData();
  }, [userData]);

  const login = async (payload) => {
    try {
      const response = await axios.post(`${BASE_URL}/login`, payload);
      setUser(response?.data?.user);
      return response.data;
    } catch (error) {
      console.error("Error during sign in:", error);
      setUser(null);
      return error;
    }
  };

  const getUserByID = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/users/${userData?.id}`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      const completeData = { ...response?.data, token: userData?.token };
      setUser(completeData);
      return response.data;
    } catch (error) {
      console.error("Error during fetching user data:", error);
      return error;
    }
  };

  const signUp = async (payload) => {
    try {
      const response = await axios.post(`${BASE_URL}/register`, payload);
      setUser(response.data?.user);
      return response.data;
    } catch (error) {
      console.error("Error during sign up:", error);
      setUser(null);
      return false;
    }
  };

  const logout = async () => {
    setUser(null);
    try {
      await localStorage.removeItem("userData");
      window.location.href = "/";
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const updateProfile = async (payload) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/users/updateProfile`,
        payload
      );
      setUser(response.data);
      return response.data;
    } catch (error) {
      console.error("Error during profile update:", error);
      setUser(null);
      return false;
    }
  };

  const getParticipants = async () => {
    setParticipantsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/participants`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        const sorted = [...response.data].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setParticipants(sorted);
      
        const total = sorted.reduce((sum, participant) => sum + (parseFloat(participant.total_amount) || 0), 0);
        setTotalAmount(total);
      } else {
        console.log("Error fetching participants");
      }
    } catch (error) {
      console.error("Error fetching participants:", error);
    } finally {
      setParticipantsLoading(false);
    }
  };

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/users`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        // console.log("Fetching users successful:", response.data);

        setUsers(response.data);

        const sorted = [...response.data].sort((a, b) => {
          if (a?.role === "admin" && b?.role !== "admin") return -1;
          if (a?.role !== "admin" && b?.role === "admin") return 1;
          return 0;
        });

        setUsers(sorted);
      } else {
        console.log("Error fetching users");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };
  const getInvoices = async () => {
  console.log("Invoices fetching...")
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/invoices`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      if (response.status === 200) {
        setInvoices(response.data.processed_invoices || []);
        console.log("Invoices fetching successful:", response.data.processed_invoices);
      } else {
        console.log("Error fetching invoices");
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleDeleteUser = async (userId) => {
    try {
      await axios.delete(`${BASE_URL}/users/${userId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });

      const updatedUsers = users.filter((user) => user.id !== userId);
      setUsers(updatedUsers);
      setTotalUsers(updatedUsers.length);
      toast.success("User deleted successfully!");
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error("Cannot delete user. The user is linked to other data.");
      } else {
        toast.error("An error occurred while trying to delete the user.");
      }
    }
  };

  const getBanners = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${BASE_URL}/banners`);
      const data = await response.json();
      if (window.innerWidth <= 768) {
        const mobileBanners = data?.banners.filter(
          (banner) => banner.platform === "mobile" || banner.platform === "both"
        );
        setBanners(mobileBanners);
      } else {
        const desktopBanners = data?.banners.filter(
          (banner) =>
            banner.platform === "desktop" || banner.platform === "both"
        );
        setBanners(desktopBanners);
      }
      setLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching about information:", error);
      setBanners([]);
      setLoading(false);
      return error;
    }
  };

  const getVideos = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/videos`);
      const data = response?.data;

      if (data.status === 200 && Array.isArray(response.data.videos)) {
        setRecentVideos(response.data.videos);
      } else {
        setRecentVideos([]);
        console.error("Unexpected response format:", response.data);
      }
      setLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching about information:", error);
      setRecentVideos([]);
      setLoading(false);
      return false;
    }
  };

  const getWinners = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/winners`);
      const data = response?.data.winners;
      setWinners(data);
      setLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching about information:", error);
      setWinners([]);
      setLoading(false);
      return error;
    }
  };
  const getProducts = async () => {
    // setLoading(true)
    try {
      const response = await axios.get(`${BASE_URL}/products`);
      setProducts(response?.data?.products);
      setLoading(false);
      return response?.data;
    } catch (error) {
      console.error("Error fetching about information:", error);
      setProducts([]);
      setLoading(false);
      return error;
    }
  };
  const addToCart = async (payload) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/cart/add`,
        payload,

        {
          headers: { Authorization: `Bearer ${userData?.token}` },
        }
      );
      getCart();
      getCartQuantity();
      return response;
    } catch (error) {
      console.error("Error adding item to cart:", error);
      setLoading(false);
      return error;
    }
  };
  const updateCart = async (payload) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/cart/updateQuantity/${payload.id}`,
        { quantity: payload?.quantity },
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      const updatedCart = await getCart(userData?.token);

      return response;
    } catch (error) {
      console.error("Error updating cart:", error);
      return error;
    }
  };

  const removeFromCart = async (payload) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/cart/removeItem/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      getCart();
      getCartQuantity();
      setLoading(false);
      return response;
    } catch (error) {
      console.error("Error removing item from cart:", error);
      setLoading(false);
      return error;
    }
  };
  const getCart = async (token) => {
    try {
      const response = await axios.get(`${BASE_URL}/cart`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });

      if (response.data?.status === 200) {
        setCartData(response.data?.data); 
        getCartQuantity();

        return response.data?.cart;
      } else {
        console.error("Unexpected response format:", response.data);
        return [];
      }
    } catch (error) {
      console.error("Error fetching cart:", error);
      setLoading(false);
      return [];
    }
  };

  const getCartQuantity = async () => {
    if (!userData?.token) {
      console.error("No token available for user");
      setCartQuantity(0);
      return;
    }
    try {
      const response = await axios.get(`${BASE_URL}/cart/quantity`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      });
      setCartQuantity(response?.data?.quantity || 0);
    } catch (error) {
      console.error("Error fetching cart quantity:", error);
      setCartQuantity(0);
    }
  };

  const getQuesions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/questions`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      const data = response?.data;
      return data;
    } catch (error) {
      console.error("Error fetching about information:", error);
      return false;
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        banners,
        winners,
        loading,
        products,
        cartData,
        videos,
        cartQuantity,
        participants,
        participantsLoading,
        totalAmount,
        users,
        invoices,
        totalUsers,
        setUsers,
        setInvoices,
        setParticipants,
        login,
        logout,
        signUp,
        getCart,
        updateCart,
        getBanners,
        getParticipants,
        getAllUsers,
        getVideos,
        addToCart,
        getProducts,
        getInvoices,
        getWinners,
        getQuesions,
        updateProfile,
        removeFromCart,
        getCartQuantity,
        getUserByID,
        handleDeleteUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within an AppProvider");
  }
  return context;
};
