import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";

function AddBlog() {
  const navigate = useNavigate();
  const { userData } = useUser();

  const [formData, setFormData] = useState({
    slug: "",
    heading: "",
    description: "",
    blog_image: null,
    seo_title: "",
    seo_description: "",
    seo_canonical: "",
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
  };

  const handleQuillChange = (value) => {
    setFormData({ ...formData, description: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/blogs`,
        {
          slug: formData.slug,
          heading: formData.heading,
          description: formData.description,
          seo_title: formData.seo_title,
          seo_description: formData.seo_description,
          seo_canonical: formData.seo_canonical,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );
      if (formData.blog_image) {
        const fileFormData = new FormData();
        fileFormData.append("blog_image", formData.blog_image);

        await axios.post(
          `${BASE_URL}/blogs/${response.data.id}/upload-image`,
          fileFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${userData?.token}`,
            },
          }
        );
      }

      toast.success("Blog added successfully!");
      navigate("/dashboard/blogs");
    } catch (error) {
      console.error("Error creating blog:", error);
      toast.error("Failed to add blog.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={setIsSidebarOpen} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="container-fluid mt-4">
            <div className="p-4 bg-light rounded shadow-sm">
              {loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "70vh" }}
                >
                  <Loader />
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h1 className="mb-4 fs-4 fw-bold text-dark">
                      Add New Blog
                    </h1>
                    <button
                      onClick={() => navigate("/dashboard/blogs")}
                      className="btn btn-secondary shadow-sm"
                    >
                      <i className="bi bi-arrow-left me-2"></i> Back to Blogs
                      List
                    </button>
                  </div>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Slug</label>
                      <input
                        type="text"
                        className="form-control"
                        name="slug"
                        value={formData.slug}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Heading</label>
                      <input
                        type="text"
                        className="form-control"
                        name="heading"
                        value={formData.heading}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <ReactQuill
                        className="bg-white"
                        theme="snow"
                        value={formData.description}
                        onChange={handleQuillChange}
                        modules={modules}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Featured Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="blog_image"
                        onChange={handleChange}
                      />
                    </div>

                    <h5 className="mt-4 mb-3">SEO Metadata</h5>
                    <div className="mb-3">
                      <label className="form-label">SEO Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="seo_title"
                        value={formData.seo_title}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">SEO Description</label>
                      <textarea
                        className="form-control"
                        name="seo_description"
                        value={formData.seo_description}
                        onChange={handleChange}
                      />
                    </div>

                    {/* <div className="mb-3">
                      <label className="form-label">Canonical URL</label>
                      <input
                        type="text"
                        className="form-control"
                        name="seo_canonical"
                        value={formData.seo_canonical}
                        onChange={handleChange}
                      />
                    </div> */}

                    <button
                      type="submit"
                      className="btn btn-primary shadow-sm w-25"
                    >
                      Publish
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
export default AddBlog;
