import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../Services/UserContext";
import { BASE_URL } from "../../Services";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import "react-toastify/dist/ReactToastify.css";

function VideoList() {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/videos`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        setVideos(response.data.videos);
      } catch (error) {
        console.error("Error fetching videos:", error);
        toast.error(
          `Error fetching videos: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/dashboard/videos/${id}/edit`);
  };

  const handleDeleteClick = async (id, closeModal) => {
    try {
      await axios.delete(`${BASE_URL}/videos/${id}`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      setVideos(videos.filter((video) => video.id !== id));
      toast.success("Video deleted successfully!");
      closeModal(false);
    } catch (error) {
      console.error("Error deleting video:", error);
      toast.error(
        `Error deleting video: ${
          error.response?.data?.message || error.message
        }`
      );
    }
  };

  const handleAddClick = () => {
    navigate("/dashboard/videos/add");
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const tableData = videos
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((video) => ({
      id: video.id,
      title: video.title,
      thumbnail: video.thumbnail ? (
        <img
          style={{ width: "100%", height: "110px", borderRadius: "10px" }}
          src={video.thumbnail}
          alt={video.title}
        />
      ) : (
        "N/A"
      ),
      username: video.user ? video.user.name : "N/A",
      reward: video.reward ? video.reward.name : "N/A",
      date_announced: video.date_announced
        ? new Date(video.date_announced).toLocaleDateString()
        : "N/A",
    }));

  const headers = [
    { id: "title", label: "Title" },
    { id: "username", label: "Username" },
    { id: "reward", label: "Reward" },
    { id: "date_announced", label: "Date" },
    { id: "thumbnail", label: "Thumbnail" },
  ];

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "70vh" }}
            >
              <Loader />
            </div>
          ) : (
            <DynamicTable
              title="Videos List"
              headers={headers}
              data={tableData}
              onEdit={handleEditClick}
              onDelete={handleDeleteClick}
              onAdd={handleAddClick}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default VideoList;
