import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUser } from "../Services/UserContext.js";
import { BASE_URL } from "../Services/index.js";
import axios from "axios";

import Header from "../UserSide/Components/HeaderComponent";
import Footer from "../UserSide/Components/FooterCompnent";
import "react-toastify/dist/ReactToastify.css";

import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  IconButton,
  Container,
  Card,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import DashboardIcon from "@mui/icons-material/Dashboard";

function ProfileScreen() {
  const { userData, logout } = useUser();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (userData) {
      setUser(userData || {});
      setName(userData.name || "");
      setEmail(userData.email || "");
      setPhone(userData.phone || "");
    }
  }, [userData]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    const updatedUserData = {
      name,
      email,
      phone,
    };

    if (password && passwordConfirmation) {
      if (password !== passwordConfirmation) {
        toast.error("Passwords do not match.");
        return;
      }
      if (password.length < 8) {
        toast.error("Password must be at least 8 characters long.");
        return;
      }

      updatedUserData.password = password;
      updatedUserData.password_confirmation = passwordConfirmation;
    }

    try {
      const response = await axios.put(`${BASE_URL}/user`, updatedUserData, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      const updatedUser = {
        ...response.data,
        token: userData.token,
      };
      setUser(updatedUser);
      localStorage.setItem("userData", JSON.stringify(updatedUser));
      toast.success("Profile updated successfully!");
    } catch (error) {
      console.error(error);
      toast.error("Error updating profile.");
    }
  };

  const handleDelete = async () => {
    if (userData.role === "admin") {
      toast.error("Admin cannot be deleted.");
      return;
    } else {
      try {
        await axios.delete(`${BASE_URL}/users/${userData.id}`, {
          headers: { Authorization: `Bearer ${userData?.token}` },
        });
        localStorage.removeItem("userData");
        setUser(null);
        logout();
        navigate("/");
        toast.success("Account deleted successfully.");
      } catch (error) {
        console.error(error);
        toast.error("Error deleting account.");
      }
    }
  };

  return (
    <Box>
      <Header />
      <div style={{ height: "10vh" }} />
      <Container sx={{ mt: 8 }}>
        <ToastContainer />
        <Card className="glow" sx={{ p: 4, boxShadow: 3, borderRadius: 2 }}>
          <Typography className="text-center" variant="h4" gutterBottom>
            {user?.name || "Profile"}

            {userData?.role === "admin" || userData?.role === "editor" ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                <Button
                  variant="contained"
                  color="primary"
                  href="/dashboard"
                  sx={{ ":hover": { color: "#ffff" } }}
                >
                  <DashboardIcon sx={{ mr: 1 }} /> Dashboard
                </Button>
              </Box>
            ) : (
              userData?.role === "viewer" && (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    href="/dashboard/stats"
                    sx={{ ":hover": { color: "#ffff" } }}
                  >
                    <DashboardIcon sx={{ mr: 1 }} /> Stats & Insights
                  </Button>
                </Box>
              )
            )}
          </Typography>

          <form onSubmit={handleUpdate}>
            <TextField
              fullWidth
              margin="normal"
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              fullWidth
              margin="normal"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Phone"
              type="text"
              value={phone}
              disabled
            />
            <Box sx={{ position: "relative" }}>
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </Box>
            <TextField
              fullWidth
              margin="normal"
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required
            />
            <Box
              sx={{
                display: "flex",
                gap: 2,
                mt: 3,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  flexWrap: "wrap",
                  flex: { xs: "1 1 100%", md: "unset" },
                  width: { xs: "100%", md: "auto" },
                }}
              >
                <Button
                  sx={{ flex: 1 }}
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Update
                </Button>
                <Button
                  sx={{ flex: 1 }}
                  variant="contained"
                  color="error"
                  onClick={() => {
                    logout();
                    navigate("/");
                  }}
                  style={{ borderWidth: 2 }}
                >
                  Logout
                </Button>
              </Box>

              {userData?.role !== "admin" && (
                <Button
                  variant="outlined"
                  color="error"
                  sx={{
                    ml: { md: "auto" }, 
                    width: { xs: "100%", md: "auto" },
                  }}
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete My Account
                </Button>
              )}
            </Box>
          </form>
        </Card>
      </Container>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">Confirm Deletion</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Are you sure you want to delete your account?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              onClick={() => setShowDeleteModal(false)}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="contained" color="error">
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <div style={{ height: "5vh" }} />
      <Footer profile={false} />
    </Box>
  );
}

export default ProfileScreen;
