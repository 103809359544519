import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../Services";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import DynamicTable from "../Components/TableComponent";
import "react-toastify/dist/ReactToastify.css";

const WinnerList = () => {
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWinners = async () => {
      try {
       const response = await axios.get(`${BASE_URL}/winners`);
        const winnersSorted = response.data.winners.sort((a, b) => 
          new Date(b.draw_date) - new Date(a.draw_date)
        );
        setWinners(winnersSorted); 
      } catch (error) {
        console.error("Error fetching winners:", error);
        toast.error(
          `Error fetching winners: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };
    fetchWinners();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={setIsSidebarOpen} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <Loader />
            </div>
          ) : (
            <DynamicTable
              title="Winners List"
              headers={[
                { id: "name", label: "Name" },
                {
                  id: "participant",
                  label: "Participant",
                  accessor: (winner) =>
                    winner.participant?.processed_invoice?.user?.name || "N/A",
                },
                { id: "draw_date", label: "Draw Date" },
                {
                  id: "reward",
                  label: "Reward",
                  accessor: (winner) => winner.reward?.name || "N/A",
                },
                { id: "ticket_id", label: "Ticket" },
                { id: "image", label: "Image", type: "image" },
              ]}
              data={winners}
              onEdit={(id) => navigate(`/winners/${id}/edit`)}
              onDelete={async (id, closeModal) => {
                await axios.delete(`${BASE_URL}/winners/${id}`);
                setWinners(winners.filter((w) => w.id !== id));
                toast.success("Winner deleted successfully!");
                closeModal(false);
              }}
              onAdd={() => navigate("/winners/create")}
            />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default WinnerList;
