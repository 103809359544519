import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useUser } from "../../../Services/UserContext";

const useMonthlyStats = () => {
  const { users, participants, products, getAllUsers, getParticipants, getProducts } = useUser();
  const [loading, setLoading] = useState(true);

  
    const fetchData = async () => {
      if (users.length && participants.length && products.length) {
        setLoading(false); 
        return;
      }

      try {
        setLoading(true);
        await Promise.all([getAllUsers(), getParticipants(), getProducts()]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    useEffect(() => {
    fetchData();
    // const interval = setInterval(() => {
    //   fetchData();
    // }, 60000);
    // return () => clearInterval(interval);
  }, []);

  const monthlyStats = useMemo(() => {
    if (!users.length || !participants.length) return [];

    const stats = {};

    users.forEach((user) => {
      const month = moment(user.created_at).format("YYYY-MM");
      if (!stats[month]) {
        stats[month] = { total_users: 0, total_participants: 0, total_quantity: 0, total_amount: 0 };
      }
      stats[month].total_users += 1;
    });

    participants.forEach((participant) => {
      const month = moment(participant.created_at).format("YYYY-MM");
      if (!stats[month]) {
        stats[month] = { total_users: 0, total_participants: 0, total_quantity: 0, total_amount: 0 };
      }
      stats[month].total_participants += 1;
      stats[month].total_amount += parseFloat(participant.total_amount) || 0;

      if (participant.processed_invoice && typeof participant.processed_invoice === "object") {
        stats[month].total_quantity += parseInt(participant.processed_invoice.total_quantity) || 0;
      }
    });

    return Object.keys(stats)
      .map((month) => ({
        month: moment(month, "YYYY-MM").format("MMMM YYYY"),
        total_users: stats[month].total_users,
        total_participants: stats[month].total_participants,
        total_quantity: stats[month].total_quantity,
        total_amount: stats[month].total_amount.toFixed(2),
        timestamp: moment(month, "YYYY-MM").toDate(), 
      }))
      .sort((a, b) => b.timestamp - a.timestamp);
  }, [users, participants]);

  const currentMonth = moment().format("MMMM YYYY");
  const previousMonth = moment().subtract(1, "months").format("MMMM YYYY");

  const currentMonthData = monthlyStats.find((item) => item.month === currentMonth);
  const previousMonthData = monthlyStats.find((item) => item.month === previousMonth);

  const calculatePaidParticipants = (participants) =>
    participants.filter((participant) => participant.total_amount > 0).length;

  const calculateTotalSales = (participants) =>
    participants.reduce((sum, participant) => sum + (parseFloat(participant.total_amount) || 0), 0);

  return {
    monthlyStats,
    currentMonthData,
    previousMonthData,
    users,
    participants,
    products,
    calculatePaidParticipants,
    calculateTotalSales,
    loading,
  };
};

export default useMonthlyStats;
