import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL } from "../../Services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";

const EditHangWinner = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    reward: "",
    draw_date: "",
    image: null,
    location: "",
  });

  const locations = ["Amanah Mall Model Town", "ChaseUp Mall gujranwala"];
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWinner = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${BASE_URL}/hang-tower-winners/${id}`
        );
        const winnerData = response.data;

        setFormData({
          name: winnerData.name,
          reward: winnerData.reward,
          draw_date: winnerData.draw_date,
          image: winnerData.image || null,
          location: winnerData.location || "",
        });
      } catch (error) {
        console.error("Error fetching winner:", error);
        toast.error("Failed to fetch winner data.");
      } finally {
        setLoading(false);
      }
    };

    fetchWinner();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({ ...prev, image: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const jsonPayload = {
        name: formData.name,
        reward: formData.reward,
        draw_date: formData.draw_date,
        location: formData.location,
        image: typeof formData.image === "string" ? formData.image : undefined,
      };
      await axios.put(`${BASE_URL}/hang-tower-winners/${id}`, jsonPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (formData.image && typeof formData.image !== "string") {
        const form = new FormData();
        form.append("image", formData.image);

        await axios.post(
          `${BASE_URL}/hang-tower-winners/${id}/upload-image`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      toast.success("Winner updated successfully!");
      navigate("/dashboard/hang-winners");
    } catch (error) {
      console.error("Error during submission:", error);
      if (error.response) {
        console.error("Server Response:", error.response.data);
        toast.error(
          error.response.data.message || "Failed to update winner data."
        );
      } else {
        console.error("Network error:", error.message);
        toast.error("Failed to update winner data.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  if (loading) {
    return (
      <div className="container mt-5 d-flex justify-content-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="container-fluid mt-4 p-5 bg-light rounded shadow-sm">
            <h1 className="mb-4">Edit Winner</h1>
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="reward" className="form-label">
                  Reward
                </label>
                <input
                  type="text"
                  name="reward"
                  className="form-control"
                  value={formData.reward}
                  onChange={handleChange}
                  placeholder="Reward"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="draw_date" className="form-label">
                  Draw Date
                </label>
                <input
                  type="date"
                  name="draw_date"
                  className="form-control"
                  value={formData.draw_date}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="location" className="form-label">
                  Location
                </label>
                <select
                  name="location"
                  className="form-control"
                  value={formData.location}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Location</option>
                  {locations.map((loc, index) => (
                    <option key={index} value={loc}>
                      {loc}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="image" className="form-label">
                  Image
                </label>
                <input
                  type="file"
                  name="image"
                  className="form-control"
                  onChange={handleFileChange}
                />
              </div>

              {formData.image && (
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  {typeof formData.image === "string" ? (
                    <div className="mb-3">
                    <img
                      src={formData.image}
                      alt="Selected"
                      style={{ width: '150px', borderRadius: '10px' }}
                    
                    />
                    </div>
                  ) : (
                    <img
                      src={URL.createObjectURL(formData.image)}
                      alt="Preview"
                      className="img-fluid"
                    />
                  )}
                </div>
              )}

              <button type="submit" className="btn btn-primary">
                Update Winner
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditHangWinner;
