import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Sidebar.css";
import Header from "./Header";
import { useUser } from "../../Services/UserContext.js";

const screenList = {
  admin: [
    { title: "Users", nav: "/dashboard", icon: "bi-house-door" },
    {
      title: "Stats & Insights",
      nav: "/dashboard/stats",
      icon: "bi bi-bar-chart",
    },
    {
      title: "Monthly Stats",
      nav: "/dashboard/monthly-stats",
      icon: "bi bi-calendar-check",
    },
    {
      title: "Participants",
      nav: "/dashboard/participants",
      icon: "bi bi-people",
    },
    { title: "Tickets", nav: "/dashboard/invoices", icon: "bi bi-receipt" },
    {
      title: "InActive Users",
      nav: "/dashboard/inactive-users",
      icon: "bi bi-person-fill-slash",
    },
    {
      title: "Ambassadors",
      nav: "/dashboard/ambassadors",
      icon: "bi bi-people-fill",
    },
    { title: "Offers", nav: "/dashboard/offers", icon: "bi bi-gift" },
    { title: "Rewards", nav: "/dashboard/rewards", icon: "bi bi-trophy" },
    { title: "Winners", nav: "/dashboard/winners", icon: "bi bi-trophy-fill" },
    {
      title: "HangWinners",
      nav: "/dashboard/hang-winners",
      icon: "bi bi-gear",
    },
    { title: "Products", nav: "/dashboard/products", icon: "bi bi-box" },
    { title: "Banners", nav: "/dashboard/banners", icon: "bi bi-image" },
    { title: "Videos", nav: "/dashboard/videos", icon: "bi bi-film" },
    { title: "Blogs", nav: "/dashboard/blogs", icon: "bi bi-journal-text" },
    { title: "FAQs", nav: "/dashboard/faqs", icon: "bi bi-question" },
    {
      title: "Questions",
      nav: "/dashboard/questions",
      icon: "bi bi-question-circle",
    },
    {
      title: "About Us",
      nav: "/dashboard/about-us",
      icon: "bi bi-info-circle",
    },
    { title: "Contacts", nav: "/dashboard/contacts", icon: "bi bi-telephone" },
  ],
  editor: [
    {
      title: "Stats & Insights",
      nav: "/dashboard/stats",
      icon: "bi bi-bar-chart",
    },
    {
      title: "Monthly Stats",
      nav: "/dashboard/monthly-stats",
      icon: "bi bi-calendar-check",
    },
    { title: "Offers", nav: "/dashboard/offers", icon: "bi bi-gift" },
    { title: "Rewards", nav: "/dashboard/rewards", icon: "bi bi-trophy" },
    { title: "Banners", nav: "/dashboard/banners", icon: "bi bi-image" },
    { title: "Winners", nav: "/dashboard/winners", icon: "bi bi-trophy-fill" },
    {
      title: "HangWinners",
      nav: "/dashboard/hang-winners",
      icon: "bi bi-gear",
    },
    { title: "Products", nav: "/dashboard/products", icon: "bi bi-box" },
    { title: "Videos", nav: "/dashboard/videos", icon: "bi bi-film" },
    { title: "Blogs", nav: "/dashboard/blogs", icon: "bi bi-journal-text" },
    { title: "FAQs", nav: "/dashboard/faqs", icon: "bi bi-question" },
    {
      title: "Questions",
      nav: "/dashboard/questions",
      icon: "bi bi-question-circle",
    },
    {
      title: "About Us",
      nav: "/dashboard/about-us",
      icon: "bi bi-info-circle",
    },
  ],
  viewer: [
    {
      title: "Stats & Insights",
      nav: "/dashboard/stats",
      icon: "bi bi-bar-chart",
    },
    {
      title: "Monthly Stats",
      nav: "/dashboard/monthly-stats",
      icon: "bi bi-calendar-check",
    },
  ],
};

function Sidebar({ onToggleSidebar }) {
  const [isOpen, setIsOpen] = useState(true);
  const [selected, setSelected] = useState("");
  const { userData } = useUser();
  const location = useLocation();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (onToggleSidebar) {
      onToggleSidebar(!isOpen);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 750) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const currentItem = screenList[userData?.role]?.find(
      (item) => item.nav === location.pathname
    );
    if (currentItem) {
      setSelected(currentItem.title);
    }
  }, [location.pathname, userData]);
  const filteredScreenList = screenList[userData?.role] || [];

  return (
    <div className="sidebar-container">
      <div style={{ flexDirection: "row" }}>
        <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <img
              onClick={() => navigate("/")}
              style={{
                width: "150px",
                height: "auto",
                margin: 10,
                cursor: "pointer",
              }}
              className="logo"
              src={require("../../UserSide/images/logo.png")}
              alt="Logo"
            />
            <button className="sidebar-toggle" onClick={toggleSidebar}>
              {isOpen ? (
                <i className="bi bi-chevron-left"></i>
              ) : (
                <i className="bi bi-chevron-right"></i>
              )}
            </button>
          </div>
          {!isOpen ? (
            <button className="sidebar-toggle-closed" onClick={toggleSidebar}>
              {isOpen ? (
                <i className="bi bi-chevron-left"></i>
              ) : (
                <i className="bi bi-chevron-right"></i>
              )}
            </button>
          ) : (
            <div></div>
          )}

          <ul className="sidebar-menu">
            {filteredScreenList.map((item, index) => (
              <SideBarElement
                key={index}
                item={item}
                selected={selected}
                onClick={setSelected}
              />
            ))}
          </ul>
        </div>
        <div>
          <Header screenName={selected} />
        </div>
      </div>
    </div>
  );
}

const SideBarElement = ({ item, selected, onClick }) => (
  <li onClick={() => onClick(item.title)}>
    <Link
      className={selected === item.title ? "sidebar-selected" : "sidebar-link"}
      to={item.nav}
    >
      <i
        className={item.icon}
        style={item.icon === "bi bi-person-fill-slash" ? {  fontSize: "16px" } : {}}
      ></i>{" "}
      {item.title}
    </Link>
  </li>
);

export default Sidebar;
