import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import Sidebar from "../sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";

function ViewBlog() {
  const { id, slug } = useParams();
  const [blog, setBlog] = useState(null);
  const { userData } = useUser();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs/${slug}`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        setBlog(response.data.blog || response.data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };
console.log("blogs", blog);
    fetchBlog();
  }, [id, userData]);

  if (!blog) {
    return (
      <div className="text-center my-5">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          <Sidebar />
        </div>
        <div className="col-md-10">
          <div className="container-fluid mt-5">
            <div className="card shadow-lg p-3 mb-5 bg-white rounded">
              <div className="card-body">
                <h1 className="card-title">{blog.heading}</h1>
                <p className="card-text">{blog.description}</p>
                {blog?.blog_image && (
                  <img
                    src={blog.blog_image}
                    alt="Blog"
                    className="img-fluid rounded"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewBlog;
