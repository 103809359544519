import React from 'react';

const FooterAppDownload = () => {
  return (
    <div>
      <h6 className="footer-title-29 pt-2 mt-4 mb-3 text-center">Download the App</h6>
      <div>
        <a href="https://play.google.com/store/apps/details?id=com.app.enaam" target='_blank'>
          <img src={require("../../images/google-play2.png")} style={{height:"90px",width:"150px"}} alt="" />
        </a>
        <a href="https://apps.apple.com/pk/app/enaam/id6450613357" target='_blank'>
          <img src={require("../../images/apple-store.png")} style={{height:"43px",width:"140px"}} alt="" />
        </a>
      </div>
    </div>
  );
}

export default FooterAppDownload;