import React, { useState } from "react";
import axios from "axios";
import { Form, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";

function AddOffers() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(""); 
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { userData } = useUser();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImageUrl(URL.createObjectURL(file)); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.status) {
      toast.error("Please select the status.");
      setLoading(false);
      return;
    }

    try {
      const form = new FormData();
      form.append("title", formData.title);
      form.append("description", formData.description);
      form.append("status", formData.status);

      const response = await axios.post(`${BASE_URL}/offers`, form, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userData?.token}`,
        },
      });

      const offerId = response.data.id;

      if (image) {
        const imageData = new FormData();
        imageData.append("image", image);

        const imageResponse = await axios.post(`${BASE_URL}/offers/${offerId}/upload-image`, imageData, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
            "Content-Type": "multipart/form-data",
          },
        });
      }

      toast.success("Offer added successfully!");
      navigate("/dashboard/offers");
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      toast.error(`Failed to add offer: ${error.response?.data?.message || error.message}`);
    } finally {
      setLoading(false);
    }
};

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div className={`col ${isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"} ms-auto`}>
          <div className="container-fluid mt-4">
            <div className="p-4 bg-light rounded shadow-sm">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Loader />
                </div>
              ) : (
                <div>
                  <h1 className="mb-4 fs-4 fw-bold text-dark">Add New Offer</h1>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Offer Image</label>
                      <input
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={handleFileChange}
                      />
                      {imageUrl && (
                        <img
                          style={{
                            height: "120px",
                            width: "150px",
                            borderRadius: "10px",
                          }}
                          src={imageUrl}
                          className="img-fluid mt-3"
                          alt="Selected"
                        />
                      )}
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Active Status</label>
                      <select
                        className="form-select ms-2"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>

                    <button type="submit" className="btn btn-primary shadow-sm">
                      Add Offer
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddOffers;