import React , {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Header from'../../Components/HeaderComponent'
import Footer from '../../Components/FooterCompnent';

const InititalGameScreen = () => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return (
        <div className="App">
            <Header />
            <div className="container-fluid col-lg-10 mt-5 p-4">
                    <div className="container-fluid mt-5 text-star ">
                        <div className="row ">
                            <div className="col-md-12 mt-5">
                                <div className="card p-4 mb-4 bg-light glow" style={{borderRadius:20}}>
                                    <h1 className="text-center fw-bold mt-4" style={{color: "#3789FF"}}>HOW TO PLAY</h1>
                                    <ol className="apply-list" style={{textAlign:"left"}}>
                                        <li >1- Answer a simple question and get a chance to win.</li>
                                        <li >2- Wait for the announcement of the winners.</li>
                                        <li >3- Whoever answers fastest wins.</li>
                                    </ol>
                                    <div className="cart-btn-wrap d-flex justify-content-center mt-4" style={{ margin: 'auto' ,}}>
                                        <button onClick={()=>{ navigate('/GameScreen'); }} style={{padding:"10px 100px 10px" }} className="btn btn-primary" id="nextButton">
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
              
            </div>
            <Footer /> 
        </div>
    );
};

export default InititalGameScreen;
